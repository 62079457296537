import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import _ from 'lodash';
import { NotarySessionStatusEnum } from "dashboard/src/types/notarizations";
import { CANCEL_NS, GET_NS, GET_NS_LIST } from "dashboard/src/lib/gql/notary-session";
import { AppThunk, RootState } from "dashboard/src/store";
import { Notarization, canceledStatuses, completedStatuses, inProgressStatuses, draftStatuses, lockedOutStatuses, pendingStatuses } from "dashboard/src/types/notarizations";
import { IndexedById } from "dashboard/src/types/paginated";

interface NotarySessionState {
  sessions: {
    byStatus: Record<string, IndexedById<Notarization>>;
    allStatuses: Array<string>;
    selectedSession: Notarization | null;
  }
};


export const initialState: NotarySessionState = {
  sessions: {
    byStatus: {},
    allStatuses: [],
    selectedSession: null,
  }
};

const slice = createSlice({
  name: 'notary-sessions',
  initialState,
  reducers: {
    setSelectedSession: (state: NotarySessionState, action: PayloadAction<{ session: Notarization }>) => {

      state.sessions.selectedSession = action.payload.session;
    },
    addNotarySessions: (state: NotarySessionState, action: PayloadAction<{ status: string, total: number, sessions: Record<string, Notarization> }>) => {
      const { status, sessions, total } = action.payload;

      const curr = (state.sessions.byStatus[status] || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Notarization>;


      curr.byId = {
        ...curr.byId,
        ...sessions
      }

      curr.allIds = _.keys(curr.byId);
      curr.total = total;

      state.sessions.byStatus[status] = curr;
      state.sessions.allStatuses = _.keys(state.sessions.byStatus)

    },
    moveNotarySessionsToCanceled: (state: NotarySessionState, action: PayloadAction<{ status: string, sessionIds: string[] }>) => {
      const { status, sessionIds: sessionsIds } = action.payload;

      const curr = (_.cloneDeep(state.sessions.byStatus[status]) || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Notarization>;

      const currCanceled = (_.cloneDeep(state.sessions.byStatus['canceled']) || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Notarization>;

      for (const ns of sessionsIds) {
        currCanceled.byId[ns] = _.cloneDeep(curr.byId[ns]);
        currCanceled.byId[ns].status = NotarySessionStatusEnum.CANCELED;
        delete curr.byId[ns];
      }

      curr.allIds = _.keys(curr.byId);
      curr.total = curr.total - sessionsIds.length;
      currCanceled.allIds = _.keys(curr.byId);
      currCanceled.total = currCanceled.total + sessionsIds.length;

      state.sessions.byStatus[status] = curr;
      state.sessions.byStatus['canceled'] = currCanceled;
      state.sessions.allStatuses = _.keys(state.sessions.byStatus)

    }
  },
});

export const addSessions = (status: string, total: number, sessionArr: Array<Notarization>): Action => slice.actions.addNotarySessions({
  status,
  total,
  sessions: _.chain(sessionArr)
    .reduce((acc, session) => ({
      ...acc,
      [session.id]: session
    }), {})
    .value()
})

export const selectSession = (session: Notarization): Action => slice.actions.setSelectedSession({ session });

export const setSelectedSession = (sessionId: string): AppThunk => async (dispatch, getState, apollo) => {
  // fetch
  const { data } = await apollo.query({
    query: GET_NS,
    variables: {
      id: sessionId
    },
  });
  const addedSession = selectSession(data.getNotarySession);
  return dispatch(addedSession);
};

export const moveSessionsToCanceled = (status: string, sessionArr: Array<string>): Action => slice.actions.moveNotarySessionsToCanceled({
  status,
  sessionIds: sessionArr
})

export interface IGetSessionsQuery {
  tabName?: string;
  statuses?: NotarySessionStatusEnum[];
  createdAfter?: string;
  notarizedByMe?: boolean;
}

interface IPaginationOptions {
  limit?: number;
  page?: number;
}

export const getNotarySessions = (
  query: IGetSessionsQuery,
  pagination: IPaginationOptions = { limit: 20, page: 1 }
): AppThunk => async (dispatch, getState, apollo) => {

  const { data } = await apollo.query({
    query: GET_NS_LIST,
    context: { service: 'enl-core-graphql' },
    variables: {
      limit: pagination.limit,
      pageIndex: pagination.page,
      statuses: query.statuses,
      createdAfter: query.createdAfter,
      notarizedByMe: query.notarizedByMe,
    },
  });

  const { getNsMany: { data: sessions, count } } = data;
  const addedSessions = addSessions(query.tabName || 'inProgress', count, sessions);

  return dispatch(addedSessions);
}

export const cancelNotarySession = (session: Notarization, query?: IGetSessionsQuery, pagination?: IPaginationOptions): AppThunk => async (dispatch, getState, apollo) => {

  const id = session.id;

  dispatch(moveSessionsToCanceled(query.tabName, [id]));

  const { data } = await apollo.mutate({
    mutation: CANCEL_NS,
    context: { service: 'enl-core-graphql' },
    variables: { id: id, },
    refetchQueries: [
      {
        query: GET_NS_LIST,
        variables: {
          limit: pagination.limit,
          pageIndex: pagination.page,
          statuses: inProgressStatuses,
        }
      },
      {
        query: GET_NS_LIST,
        variables: {
          limit: pagination.limit,
          pageIndex: 0,
          statuses: completedStatuses,
        }
      },
      {
        query: GET_NS_LIST,
        variables: {
          limit: pagination.limit,
          pageIndex: 0,
          statuses: canceledStatuses,
        },
      },
      {
        query: GET_NS_LIST,
        variables: {
          limit: pagination.limit,
          pageIndex: 0,
          statuses: draftStatuses,
        }
      },
      {
        query: GET_NS_LIST,
        variables: {
          limit: pagination.limit,
          pageIndex: 0,
          statuses: lockedOutStatuses,
        }
      },
      {
        query: GET_NS_LIST,
        variables: {
          limit: pagination.limit,
          pageIndex: 0,
          statuses: pendingStatuses,
        }
      }
    ],
    awaitRefetchQueries: true,
  });

  return data.cancelNotarySession;
}





export const reducer = slice.reducer;
export const selector = (state: RootState) => state.notarySessions
export default slice;

