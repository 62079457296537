import type { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },

    // override MUI classes globally as needed below
    // here's an example for applying a custom breakpoint to a MUI class 
    '.MuiTableCell-body, .MuiTypography-body1': {
      [theme.breakpoints.down(960)]: {
        fontSize: '.8rem !important'
      }
    },

    // creating a namespace for global styles so other devs
    // know to look in this file for 'magic' classNames
    '.global_iconButtonSmall': {
      [theme.breakpoints.down(960)]: {
        transform: 'scale(.8)',
        padding: 4
      }
    }
  },

}));

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
