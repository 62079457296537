import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme, getTheme, getThemeAsPreview, savePreviewAsTheme, selector, setPreviewLogo, setPreviewMode, setPreviewTheme } from 'dashboard/src/slices/theme';

const themeNameSelector = createSelector(selector, (state) => state.name);
const themeColorsSel = createSelector(selector, (state) => state.theme);
const themePreviewModeSel = createSelector(selector, (state) => state.previewMode);
const themePreviewOptionsSel = createSelector(selector, (state) => state.previewTheme);
const logoSel = createSelector(selector, (state) => state.logo);
const previewLogoSel = createSelector(selector, (state) => state.previewLogo);
const uploadedLogoSel = createSelector(selector, (state) => state.uploadedLogo);

export default function useTheme() {
  const dispatch = useDispatch();
  const themeName = useSelector(themeNameSelector);
  const themeColors = useSelector(themeColorsSel);
  const themePreviewMode = useSelector(themePreviewModeSel);
  const themePreviewOptions = useSelector(themePreviewOptionsSel);
  const logo = useSelector(logoSel);
  const uploadedLogo = useSelector(uploadedLogoSel);
  const previewLogo = useSelector(previewLogoSel);

  return {
    logo,
    uploadedLogo,
    themeName,
    themeColors,
    themePreviewMode,
    themePreviewOptions,
    previewLogo,
    changeTheme: (themeName, theme, logo, uploadedLogo) => dispatch(changeTheme(themeName, theme, logo, uploadedLogo)),
    setPreviewTheme: (themeOptions) => dispatch(setPreviewTheme(themeOptions)),
    setPreviewMode: (previewMode) => dispatch(setPreviewMode(previewMode)),
    setPreviewLogo: (previewLogo) => dispatch(setPreviewLogo(previewLogo)),
    savePreviewAsTheme: () => dispatch(savePreviewAsTheme()),
    getTheme: () => dispatch(getTheme()),
    getThemeAsPreview: () => dispatch(getThemeAsPreview()),
  }
}
