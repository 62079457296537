import { Notary, User } from './user';
import { Transaction } from './transaction';

export enum NotarySessionStatusEnum {
  PENDING_DOC_UPLOAD = 'pendingDocUpload',
  PENDING_VERIFICATION = 'pendingVerification',
  READY = 'ready',
  PENDING_START = 'pendingStart',
  IN_PROGRESS = 'inProgress',
  PENDING_PAYMENT = 'pendingPayment',
  COMPLETE_SUCCESS = 'completeSuccess',
  COMPLETE_FAIL = 'completeFail',
  COMPLETE_TERMINATED = 'completeTerminated',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}

export interface Organization {
  id: string;
}

export interface Document {
  id: string;
  title: string;
  description?: string;
  notarizationType?: string;
}
export interface Request {
  id: string;
  type?: string;
  dueDate?: string;
  expiresAt?: string;
  sender?: User;
  assigned?: User;
  assignedToOrg: string;
  docs: Document[];
}
export interface Notarization {
  id: string;
  primarySigner: User;
  type: string;
  sendingOrg: string;
  notarizationOrg: string;
  organization?: Organization;
  notarySessionUsers: User[];
  completedAt?: string;
  updatedAt?: string;
  createdAt?: string;
  paidBy: string;
  status: NotarySessionStatusEnum;
  request?: Request;
  notarizationType?: string;
  sessionDescription?: string;
  notary?: Notary;
  computedStatus?: ComputedStatus;
  transaction?: Transaction;
  creatorId?: string;
  updaterId?: string;
}

export interface ComputedStatus {
  emailSent: boolean;
  emailVerified: boolean;
  taggingRequested: boolean;
  taggingCompleted: boolean;
  completed: boolean;
}

export const pendingStatuses: NotarySessionStatusEnum[] = [
  NotarySessionStatusEnum.PENDING_DOC_UPLOAD,
  NotarySessionStatusEnum.PENDING_VERIFICATION,
  NotarySessionStatusEnum.READY,
  NotarySessionStatusEnum.PENDING_START,
  NotarySessionStatusEnum.PENDING_PAYMENT,
];
export const inProgressStatuses: NotarySessionStatusEnum[] = [
  NotarySessionStatusEnum.IN_PROGRESS,
];
export const completedStatuses: NotarySessionStatusEnum[] = [
  NotarySessionStatusEnum.COMPLETE_SUCCESS,
];
export const canceledStatuses: NotarySessionStatusEnum[] = [
  NotarySessionStatusEnum.CANCELED,
  NotarySessionStatusEnum.COMPLETE_TERMINATED,
  NotarySessionStatusEnum.COMPLETE_FAIL,
];
// TODO: Update below with correct enums once the DB enum is updated
export const draftStatuses: NotarySessionStatusEnum[] = [];
export const lockedOutStatuses: NotarySessionStatusEnum[] = [];
