import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  // Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'dashboard/src/constants';
import Logo from 'dashboard/src/components/Logo';
import useTheme from 'dashboard/src/hooks/useTheme';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { previewLogo } = useTheme()

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color='default'
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to='/'>
          <Logo
            className={classes.logo}
            src={previewLogo}
          />
        </RouterLink>
        <Typography
          variant='caption'
          color='textSecondary'
          style={{
            display: "none",
          }}
        >
          Version
          {' '}
          {APP_VERSION}
        </Typography>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color='textSecondary'
          component={RouterLink}
          to='/app'
          underline='none'
          variant='body2'
        >
          Dashboard
        </Link>
        <Link
          className={classes.link}
          color='textSecondary'
          component={RouterLink}
          to='/docs'
          underline='none'
          variant='body2'
        >
          Documentation
        </Link>
        <Divider className={classes.divider} />
        <Button
          color='primary'
          component='a'
          href='https://material-ui.com/store/items/devias-kit-pro'
          variant='contained'
          size='small'
        >
          Get the kit
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
