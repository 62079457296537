import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import _ from 'lodash';
import { GET_ALL_ORGANIZATIONS_QUERY, GET_ALL_ORGANIZATIONS_DROPDOWN_QUERY, GET_ORGANIZATION_INFO } from "dashboard/src/lib/gql/organization";
import { AppThunk, RootState } from "dashboard/src/store";
import { Auth0Organization } from "dashboard/src/types/organization";
import { IndexedById } from "dashboard/src/types/paginated";


interface OrganizationState {
  organizations: IndexedById<Auth0Organization>;
  orgsBeingFetched: Record<string, boolean>; // Key is organizationId, value tells you if that organization is currently being fetched. Good for avoiding double-fetching
};

export const initialState: OrganizationState = {
  organizations: {
    byId: {},
    allIds: [],
    total: 0
  },
  orgsBeingFetched: {},
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    addOrganizations: (state: OrganizationState, action: PayloadAction<{ total: number, organizations: Record<string, Auth0Organization> }>) => {
      const { organizations, total } = action.payload;

      const curr = (state.organizations || {
        byId: {},
        allIds: [],
        total: 0
      }) as IndexedById<Auth0Organization>;

      curr.byId = {
        ...curr.byId,
        ...organizations
      }
      curr.allIds = _.keys(curr.byId);
      curr.total = total;

      state.organizations.byId = curr.byId;
      state.organizations.allIds = curr.allIds;
      state.organizations.total = curr.total;

    },
    setFetchingStatus(state, { payload }: PayloadAction<{ id: string, isFetching: boolean }>) {
      state.orgsBeingFetched[payload.id] = payload.isFetching;
    },
    addOrganization(state, { payload: { organization } }: PayloadAction<{ organization: Auth0Organization }>) {
      state.organizations.byId[organization.id] = organization;
      if (!state.organizations.allIds.includes(organization.id)) {
        state.organizations.allIds.push(organization.id);
      }
    }
  }
});

export const addOrganizations = (total: number, organizationArr: Array<Auth0Organization>): Action => slice.actions.addOrganizations({
  total,
  organizations: _.chain(organizationArr)
    .reduce((acc, organization) => ({
      ...acc,
      [organization.id]: organization
    }), {})
    .value()
});

interface IPaginationOptions {
  limit?: number;
  page?: number;
}

export const getOrganizations = (pagination: IPaginationOptions = { limit: 20, page: 0 }): AppThunk => async (dispatch, getState, apollo) => {
  const { data } = await apollo.query({
    query: GET_ALL_ORGANIZATIONS_QUERY,
    context: { service: 'enl-graphql' },
    variables: {
      pageIndex: pagination.page,
      limit: pagination.limit || 10,
    },
  });
  const { data: organizations, count = 10 } = data.findAllOrganization;

  return dispatch(addOrganizations(count, organizations));
}

export const getOrganizationsDropdown = (pagination: IPaginationOptions = { limit: 20, page: 0 }): AppThunk => async (dispatch, getState, apollo) => {
  const { data } = await apollo.query({
    query: GET_ALL_ORGANIZATIONS_DROPDOWN_QUERY,
    context: { service: 'enl-graphql' },
    variables: {
      pageIndex: pagination.page,
      limit: pagination.limit || 10,
    },
  });
  const { data: organizations, count = 10 } = data.findAllOrganization;

  return dispatch(addOrganizations(count, organizations));
}

export const { addOrganization } = slice.actions;

export const getOrganization = (organizationId: string): AppThunk => async (dispatch, getState, apollo) => {
  if (getState().organizations.orgsBeingFetched[organizationId]) {
    return;
  }

  dispatch(slice.actions.setFetchingStatus({ id: organizationId, isFetching: true }));
  try {
    const { data: { getOrganizationInfo } } = await apollo.query({
      query: GET_ORGANIZATION_INFO,
      variables: { organizationId },
    });

    return dispatch(addOrganization({ organization: getOrganizationInfo }));
  } catch (err) {
    dispatch(slice.actions.setFetchingStatus({ id: organizationId, isFetching: false }));
    throw err;
  }
}

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.organizations
export default slice;
