import { gql } from '@apollo/client';

const fieldsToRetrieve = `
id,
requestId,
sendingOrg,
notarizationOrg,
paidBy,
status,
updatedAt,
createdAt,
completedAt,
sessionDescription,
notarizationType,
taggingOrganizationId,
notary {
  id
  commissionId
  commissionState
  user {
    id
    firstName
    lastName
  }
}
request {
  id,
  type,
  requestGroupId,
  assignedToOrg
  expiresAt
  dueDate,
  sender {
    id
    firstName
    lastName
  }
  assigned {
    id
    firstName
    lastName
    notary {
      id
      commissionId
      commissionState
    }
  },
  docs {
    id,
    title,
    description,
    notarizationType
  }
}
primarySigner {
  id
  user {
    id
    firstName
    middleName
    lastName
    email
  }
}
notarySessionUsers {
  id
  type,
  kbaRequired,
  credAnalysisRequired,
  kbaPassed,
  documentVerified,
  docVerifyIdType,
  secondaryIdentificationUploaded
  
  user {
    id
    firstName
    middleName,
    lastName,
    email,
    address1,
    address2,
    city,
    state,
    zip,
    emailVerified
  }
}`

export const GET_NS_LIST = gql`
  # Write your query or mutation here
  query getNs(
    $limit: Int!, 
    $pageIndex: Int!, 
    $statuses: [EnumNotarySessionStatus], 
    $createdAfter: String,
    $notarizedByMe: Boolean,
  ) {
    getNsMany(
      limit:$limit, 
      pageIndex:$pageIndex, 
      statuses: $statuses, 
      createdAfter: $createdAfter,
      notarizedByMe: $notarizedByMe,
    ) {
      data {
        ${fieldsToRetrieve}
      }
      count
      pageIndex
    }
  }
`;

export const GET_NS = gql`
  # Write your query or mutation here
  query getNs($id: ID!) {
    getNotarySession(id: $id) {
      ${fieldsToRetrieve}
    }
  }
`;

export const CANCEL_NS = gql`
mutation cancelNotarySession($id: ID!) {
  cancelNotarySession(id: $id)
}`;


export const GET_NS_GRAPH_LIST = gql`
  # Write your query or mutation here
  query getNsGraph(
    $limit: Int!, 
    $pageIndex: Int!, 
    $statuses: [EnumNotarySessionStatus], 
    $createdAfter: String,
    $notarizedByMe: Boolean,
  ) {
    getNsMany(
      limit:$limit, 
      pageIndex:$pageIndex, 
      statuses: $statuses, 
      createdAfter: $createdAfter,
      notarizedByMe: $notarizedByMe,
    ) {
      data {
        id,
        status,
        updatedAt,
        completedAt,
        createdAt,
      }
      count
      pageIndex
    }
  }
`;

export const GET_NS_COUNT = gql`
  # Write your query or mutation here
  query getNsCount(
    $statuses: [EnumNotarySessionStatus], 
  ) {
    getNsMany(
      limit: 1000000000,
      pageIndex: 0,
      statuses: $statuses, 
    ) {
      count
    }
  }
`;