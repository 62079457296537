/* eslint-disable react-hooks/rules-of-hooks */

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useCan from '../hooks/useCan';
import { selector as authSelector } from 'dashboard/src/slices/auth';

interface PermissionGuardParameters{
  featureGates?: string[];
  resources?: string[];
}

const PermissionGuard = ({ featureGates, resources } : PermissionGuardParameters) => (props) => {
  const { children } = props;

  const { all } = useCan();
  const { orgFeatures } = useSelector(authSelector);

  if (orgFeatures && Array.isArray(featureGates) && featureGates.length > 0) {
    const hasValidFeatureGateSettings = featureGates.reduce(
      (accum, val) => accum && orgFeatures.includes(val),
      true,
    );

    if (!hasValidFeatureGateSettings) {
      return <Redirect to='/403' />;
    }
  }

  if (Array.isArray(resources) && resources.length > 0) {
    const hasValidPermissionSettings = all(...resources);

    if (!hasValidPermissionSettings) {
      return <Redirect to='/403' />;
    }
  }

  return children;
};

export default PermissionGuard;
