import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'dashboard/src/slices/calendar';
import { reducer as chatReducer } from 'dashboard/src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'dashboard/src/slices/kanban';
import { reducer as mailReducer } from 'dashboard/src/slices/mail';
import { reducer as notificationReducer } from 'dashboard/src/slices/notification';
import { reducer as authReducer } from 'dashboard/src/slices/auth';
import { reducer as esignSessionsReducer } from 'dashboard/src/slices/esign-sessions';
import { reducer as notarySessionsReducer } from 'dashboard/src/slices/notary-sessions';
import { reducer as usersReducer } from 'dashboard/src/slices/user';
import { reducer as organizationsReducer } from 'dashboard/src/slices/organizations';
import { reducer as themeReducer } from 'dashboard/src/slices/theme';
import { reducer as adminConsoleReducer } from 'dashboard/src/slices/admin-console';
import { reducer as taggingReducer } from 'dashboard/src/slices/tagging';
import { reducer as auditActionsReducer } from 'dashboard/src/slices/audit-actions';

const rootReducer = combineReducers({
  auditActions: auditActionsReducer,
  auth: authReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  esignSessions: esignSessionsReducer,
  notarySessions: notarySessionsReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  theme: themeReducer,
  organizations: organizationsReducer,
  users: usersReducer,
  adminConsole: adminConsoleReducer,
  tagging: taggingReducer,
});

export default rootReducer;
