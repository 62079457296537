import { createSlice, current, PayloadAction, Action } from "@reduxjs/toolkit";
import _ from 'lodash';
import { GET_ALL_ESIGNS_QUERY, GET_ESIGN, GET_ESIGN_BY_ID, GET_LOCKED_ESIGNS, UNBLOCK_SESSION_BY_ID } from "@enotarylog/gql/esign";
import { AppThunk, RootState } from "dashboard/src/store";
import { Esign } from "dashboard/src/types/esign";
import { IndexedById } from "dashboard/src/types/paginated";
import objFromArray from "dashboard/src/utils/objFromArray";
import { GET_TAGGING_QUEUE_PAGE } from "../lib/gql/tagging";
import { SEARCH_ORDERS_QUERY } from '@enotarylog/gql/orders'

interface EsignState {
  sessions: {
    byStatus: Record<string, IndexedById<Esign>>;
    allStatuses: Array<string>;
    selectedSession: Esign | null;
  }
};

export const initialState: EsignState = {
  sessions: {
    byStatus: {},
    allStatuses: [],
    selectedSession: null,
  }
};

const slice = createSlice({
  name: 'esign-sessions',
  initialState,
  reducers: {
    setSelectedSession: (state: EsignState, action: PayloadAction<{ session: Esign }>) => {
      state.sessions.selectedSession = action.payload.session;
    },
    addSessions: (state: EsignState, action: PayloadAction<{ status: string, total: number, sessions: Record<string, Esign>, page: number, limit: number}>) => {
      const { status, sessions, total, page, limit } = action.payload;
      const currentState = current(state);

      const esignsToAdd = Object.values(sessions);
      const newIds = esignsToAdd.map((session) => session.id);

      const sortedById = esignsToAdd.reduce((result, esign) => {
        result[esign.id] = esign;
        return result;
      }, {});

      const curr = ({
        byId: sortedById,
        allIds: Object.keys(sessions),
        total: total
      }|| {
        byId: {},
        allIds: [],
        total: 0
      }
      ) as IndexedById<Esign>;

      curr.byId = { ...(currentState?.sessions?.byStatus[status]?.byId || {}), ...sortedById };
      curr.allIds = _.uniq([ ...newIds ]);
      curr.total = total;

      state.sessions.byStatus[status] = curr;
      state.sessions.allStatuses = _.keys(state.sessions.byStatus)
    },
    clearSelectedSession(state) {
      state.sessions.selectedSession = null;
    },
    updateSessionById: (state: EsignState, action: PayloadAction<Esign>) => {
      const status = action.payload.transaction.lockedOut === true ? 'locked_out' : action.payload.transaction.status;
      const id = action.payload.id;

      if (!state.sessions.byStatus[status]) {
        state.sessions.byStatus[status] = {
          byId: {},
          allIds: [],
          total: 0
        };
      }
      const statusSlice = state.sessions.byStatus[status];
      const byId = statusSlice.byId;

      byId[id] = {
        ...byId[id],
        ...action.payload
      }


      state.sessions.byStatus[status].byId = byId;
    },
    unlockSessionById: (state: EsignState, action: PayloadAction<{ transactionId: string }>) => {
      const isLockedStatus = state.sessions.byStatus['locked_out'];
      const [esignRecord] = Object.values(isLockedStatus.byId)
        .filter((record) => record.transactionId === action.payload.transactionId);

      delete isLockedStatus.byId[esignRecord.id];

      isLockedStatus.allIds = isLockedStatus.allIds
        .filter((x) => x !== esignRecord.id)

      isLockedStatus.total -= 1;

      if (isLockedStatus.allIds.length === 0) {
        state.sessions.allStatuses = state.sessions.allStatuses.filter((s) => s !== "locked_out");
      }
    },
    clearSessions: (state: EsignState, action: PayloadAction<{ status: string }>) => {
      const { status } = action.payload;
      state.sessions.byStatus[status].byId = {};
      state.sessions.byStatus[status].allIds = [];
      state.sessions.byStatus[status].total = 0;
    },
  },
});

export const selectSession = (session: Esign): Action => slice.actions.setSelectedSession({ session });

export const setSelectedSession = (sessionId: string): AppThunk => async (dispatch, getState, apollo) => {
  // fetch
  const { data } = await apollo.query({
    query: GET_ESIGN,
    variables: {
      id: sessionId
    },
  });

  const addedSession = selectSession(data.getEsign);
  return dispatch(addedSession);
};
export const addSessions = (status: string, total: number, sessionArr: Array<Esign>, page: number, limit: number): Action => slice.actions.addSessions({
  status,
  total,
  sessions: _.chain(sessionArr)
    .reduce((acc, session) => ({
      ...acc,
      [session.id]: session
    }), {})
    .value(),
  page,
  limit,
})

export const clearSessions = (status: string): Action => slice.actions.clearSessions({ status });

interface IGetSessionsQuery {
  status?: string;
  mySessionsOnly?: boolean;
}

interface IPaginationOptions {
  limit?: number;
  page?: number;
}

export const getSessions = (query: IGetSessionsQuery, pagination: any): AppThunk => async (dispatch, getState, apollo) => {
  const { auth } = getState();
  if (query.status === 'tagging_queue') {
    const { data: { taggableTransactions } } = await apollo.query({
      query: GET_TAGGING_QUEUE_PAGE,
      variables: { pagination: { pageIndex: (pagination.page || 1) - 1, limit: pagination.limit }, mySessionsOnly: query.mySessionsOnly },
      fetchPolicy: 'no-cache',
    });
    const { total, transactions } = taggableTransactions;

    //TODO: Remove if participant email values are available
    const updatedTransaction = transactions.map((tag) => {
      const updatedValue = { ...tag };
      // eslint-disable-next-line array-callback-return
      tag.participants.map((par, i) => {
        if (par.email === null) {
          updatedValue.participants[i].email = 'N/A';
        }
      })
      return updatedValue
    })

    return dispatch(addSessions(query.status || 'pending', total, updatedTransaction, pagination.page, pagination.limit));

  }
  else {
    const { data } = await apollo.query({
      query: GET_ALL_ESIGNS_QUERY,
      context: { service: 'enl-graphql' },
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          status: query.status || 'pending',
          creatorId: query.mySessionsOnly ? auth.user.auth0Id : undefined,
        },
        pagination: {
          limit: pagination.limit || 20,
          pageIndex: (pagination.page || 1) - 1,
        }
      }
    });

    const { getEsignByOrgId: { data: sessions, total } } = data;
    return dispatch(addSessions(query.status || 'pending', total, sessions, pagination.page, pagination.limit));
  }
}

export const getLockedEsignSessions = (pagination: IPaginationOptions = { limit: 20, page: 1 }, mySessionsOnly?: boolean): AppThunk => async (dispatch, getState, apollo) => {
  const { auth } = getState();
  const { data } = await apollo.query({
    query: GET_LOCKED_ESIGNS,
    context: { service: 'enl-graphql' },
    fetchPolicy: 'no-cache',
    variables: {
      mySessionsOnly,
      pagination: {
        limit: pagination.limit || 20,
        pageIndex: (pagination.page || 1) - 1,
      }
    }
  });

  const { getLockedEsigns: { data: sessions, total } } = data;

  return dispatch(addSessions('locked_out', total, sessions, pagination.page, pagination.limit));
}

export const getEsignSessionById = (id: string): AppThunk => async (dispatch, getState, apollo) => {
  const { data } = await apollo.query({
    query: GET_ESIGN_BY_ID,
    context: { service: 'enl-graphql' },
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  const { esign } = data;

  return dispatch(slice.actions.updateSessionById(esign));
}

// TODO: move to transaction slice
export const unlockTransaction = (id: string): AppThunk => async (dispatch, getState, apollo) => {
  await apollo.mutate({
    mutation: UNBLOCK_SESSION_BY_ID,
    context: { service: 'enl-graphql' },
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  return dispatch(slice.actions.unlockSessionById({ transactionId: id }));
}

export const searchTransactions = (query: string, status?: string, mySessionsOnly?: boolean): AppThunk => async (dispatch, getState, apollo) => {
  const { data } = await apollo.query({
    query: SEARCH_ORDERS_QUERY,
    context: { service: 'enl-graphql' },
    fetchPolicy: 'no-cache',
    variables: {
      query,
      status,
      mySessionsOnly,
    }
  });
  const sessions = data.searchTransactions.data;
  const total = sessions.length;

  return dispatch(addSessions(status || 'pending', total, sessions, 0, 200));
};

export const { clearSelectedSession } = slice.actions;

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.esignSessions
export default slice;

