import { gql } from '@apollo/client';

export const GET_ALL_ESIGNS_QUERY = gql`
  query getEsignSessions(
    $query: EsignQueryInput
    $pagination: PaginationInput
  ) {
    getEsignByOrgId(query: $query, pagination: $pagination) {
      data {
        id
        canceledAt
        createdAt
        completedAt
        creatorId
        passwordHint
        transactionId
        updatedAt
        status
        signingOrder
        personalizedMessage
        sendEmailNotification
        signers {
          authenticationType
          authenticationPassed
          credAnalysisPassed
          credAnalysisRequired
          secondaryIdentificationRequired
          credAnalysisType
          email
          emailVerified
          firstName
          id
          kbaPassed
          kbaRequired
          lastName
          middleName
          primary
          type
          status
          transactionId
          order
          address1
          address2
          city
          state
          zip
          skipVerification
        }
        documents {
          id
          transactionId
          title
          description
          sessionType
          type
          status
          pages
          docSize
          gcsRefId
          order
          readOnly
          participants {
            id
            visible
            readOnly
          }
        }
      }
      count
      pageCount
      page
      total
    }
  }
`;

export const GET_LOCKED_ESIGNS = gql`
  query getLockedEsignSessions($pagination: PaginationInput, $mySessionsOnly: Boolean!) {
    getLockedEsigns(pagination: $pagination, mySessionsOnly: $mySessionsOnly) {
      data {
        id
        canceledAt
        createdAt
        completedAt
        passwordHint
        transactionId
        transaction {
          lockedAt
        }
        updatedAt
        status
        signers {
          authenticationType
          authenticationPassed
          credAnalysisPassed
          credAnalysisRequired
          secondaryIdentificationRequired
          credAnalysisType
          email
          emailVerified
          firstName
          id
          kbaPassed
          kbaRequired
          lastName
          middleName
          primary
          type
          order
          address1
          address2
          city
          state
          zip
          skipVerification
        }
        documents {
          id
          transactionId
          title
          description
          sessionType
          type
          status
          pages
          gcsRefId
          order
          readOnly
          participants {
            id
            visible
            readOnly
          }
        }
      }
      count
      pageCount
      page
      total
    }
  }
`;

export const UNBLOCK_SESSION_BY_ID = gql`
  mutation unblockSessionById($id: String!) {
    transaction: unlockTransaction(id: $id) {
      id
      status
      failureReason
      lockedOut
      documents {
        id
        title
        status
        url
      }
      auditLogs {
        entity
        event
        entityId
        createdAt
        data
      }
    }
  }
`;

export const GET_ESIGN_BY_ID = gql`
  query getEsignById($id: String!) {
    esign: getEsignById(id: $id) {
      id
      authenticationType
      authenticationPassed
      status
      signingOrder
      creatorId
      paidByOrganization
      transactionId
      docs
      signers {
        id
        primary
        firstName
        lastName
        middleName
        email
        address1
        address2
        city
        phone
        state
        zip
        documents {
          documentId
        }
      }
      transaction {
        id
        status
        failureReason
        lockedOut
        documents {
          id
          title
          url
        }
        auditLogs {
          entity
          event
          entityId
          createdAt
          data
        }
      }
    }
  }
`;

export const GET_GRAPH_ESIGNS_QUERY = gql`
  query getEsignSessionsGraph(
    $query: EsignQueryInput
    $pagination: PaginationInput
  ) {
    getEsignByOrgId(query: $query, pagination: $pagination) {
      data {
        id
        canceledAt
        createdAt
        completedAt
        status
      }
      count
      pageCount
      page
      total
    }
  }
`;

export const GET_ESIGNS_COUNT = gql`
  query getEsignSessionsCount($query: EsignQueryInput) {
    getEsignByOrgId(
      query: $query
      pagination: { limit: 1000000000, pageIndex: 0 }
    ) {
      count
    }
  }
`;

export const COMMON_ESIGN_PARTS = ({
  personalPasswordAnswer = false,
} = {}) => gql`
  fragment commonEsignParts on Esign {
    id
    status
    createdAt
    completedAt
    signingOrder
    personalizedMessage
    updatedAt
    updaterId
    creatorId
    sendEmailNotification
    transaction {
      id,
      status
      failureReason
      lockedOut
      documents {
        id
        title
        url
      }
      auditLogs {
        entity
        event
        entityId
        createdAt
        data
      }
    }
    documents {
      id
      title
      description
      sessionType
      type
      pages
      gcsRefId
      xfdf
      tags
      order
      status
      url
      templateId
    }
    signers {
      address1
      address2
      authenticationPassed
      authenticationType
      city
      country
      credAnalysisPassed
      credAnalysisRequired
      credAnalysisType
      documents {
        documentId
        participantId
        visible
        readOnly
      }
      email
      firstName
      id
      kbaPassed
      kbaRequired
      lastName
      middleName
      order
      ${personalPasswordAnswer ? 'personalPasswordAnswer' : ''}
      personalPasswordQuestion
      phone
      primary
      remote
      secondaryIdentificationRequired
      skipVerification
      state
      type
      templateRoleId
      zip
    }
  }
`;
export const GET_ESIGN = gql`
  query getEsign($id: String!) {
    getEsign(id: $id) {
      ...commonEsignParts
    }
  }
  ${COMMON_ESIGN_PARTS()}
`;
export const GET_ESIGN_BY_TRANSACTION_ID = ({
  personalPasswordAnswer = false,
} = {}) => gql`
  query getEsignByTransactionId($id: String!) {
    getTransaction(id: $id) {
      esignSessions {
        ...commonEsignParts
      }
      auth0OrganizationId
      status
      taggingOrganizationId
    }
  }
  ${COMMON_ESIGN_PARTS({ personalPasswordAnswer })}
`;

export const UPDATE_ESIGN = gql`
  mutation editEsign(
    $transactionId: ID!
    $documents: [DocumentInput]
    $documentParticipants: [DocumentParticipantInput]
    $participants: [ParticipantInput]
    $status: String
    $signingOrder: Boolean
    $collaboratingOrganizationAuth0Id: String
    $notificationRecipientEmailAddresses: [String]
    $sessionContacts: [SessionContactInfo]
    $referenceId: String
    $idvForESign: Boolean
    $mismoCompliant: Boolean
    $personalizedMessage: String
    $updaterId: String
    $creatorId: String
    $taggingOrganizationId: String
    $notifyParticipants: NotifyParticipantsInput
  ) {
    editEsign(
      transactionId: $transactionId
      documents: $documents
      documentParticipants: $documentParticipants
      participants: $participants
      status: $status
      signingOrder: $signingOrder
      collaboratingOrganizationAuth0Id: $collaboratingOrganizationAuth0Id
      notificationRecipientEmailAddresses: $notificationRecipientEmailAddresses
      sessionContacts: $sessionContacts
      referenceId: $referenceId
      idvForESign: $idvForESign
      mismoCompliant: $mismoCompliant
      personalizedMessage: $personalizedMessage
      creatorId: $creatorId
      taggingOrganizationId: $taggingOrganizationId
      updaterId: $updaterId
      notifyParticipants: $notifyParticipants
    )
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($transaction: CreateTransactionInput) {
    createTransaction(transaction: $transaction) {
      id
      sessionId
      esignSessions {
        id
      }
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation resendInvite($transactionId: String, $participantId: String) {
    resendInvite(transactionId: $transactionId, participantId: $participantId) {
      transactionId
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction(
    $transactionId: String
    $transaction: TransactionUpdateInput
  ) {
    updateTransaction(
      transactionId: $transactionId
      transaction: $transaction
    ) {
      id
    }
  }
`;

export const ESIGN_SUBSCRIPTION = gql`
  subscription esignOrders($jwt: String!) {
    esignOrders(jwt: $jwt) {
      id
      status
    }
  }
`;

export const ESIGN_SEARCH = gql`
  query ($query: String!, $status: String!) {
    searchEsignTransactions(query: $query, status: $status)
  }
`;

export const ESIGN_CANCEL = gql`
  mutation cancelEsign($id: String!) {
    cancelEsign(id: $id)
  }
`;

export const ESIGN_UNCANCEL = gql`
  mutation uncancelEsign($id: String!) {
    uncancelEsign(id: $id)
  }
`;

export const ESIGN_DELETE = gql`
  mutation deleteEsign($id: String!) {
    deleteEsign(id: $id)
  }
`;
