export interface FileToBase64Options {
  overrideMime?: string;
  // Do NOT set this to true unless you absolutely have to, it removes info that may be very difficult to recover, and will cause you to not get a valid base64 url
  notUsedAsAUrl?: boolean;
};

export const fileToBase64 = (file, options: FileToBase64Options = {}): Promise<string> => new Promise((resolve) => {
  const reader = new FileReader();

  // Read file content on file loaded event
  reader.onload = function (event) {
    const result = event.target.result as string;
    if (options.notUsedAsAUrl) {
      resolve(stripDataUrl(result));
    } else if (options.overrideMime) {
      resolve(prependDataUrl(stripDataUrl(result), options.overrideMime));
    } else {
      resolve(result);
    }
  };

  // Convert data to base64
  reader.readAsDataURL(file);
});

export const prependDataUrl = (base64: string, fileType = 'image/png') => (`data:${fileType};base64, ${base64}`)
export const stripDataUrl = (dataUrl: string) => dataUrl.split(',')[1]

