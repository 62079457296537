import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET_AUDIT_ACTIONS } from "dashboard/src/lib/gql/audit-actions";
import { AppThunk, RootState } from "dashboard/src/store";
import { AuditAction } from 'dashboard/src/types/audit-action';

interface AuditActionState {
  auditActions: {
    selectedAuditActions: AuditAction[] | null;
  }
};


export const initialState: AuditActionState = {
  auditActions: {
    selectedAuditActions: null,
  }
};

const slice = createSlice({
  name: 'audit-actions',
  initialState,
  reducers: {
    setSelectedAuditActions: (state: AuditActionState, action: PayloadAction<AuditAction[]>) => {

      state.auditActions.selectedAuditActions = action.payload;
    },
  }
});

export const selectAuditActions = (sessionId): Action => slice.actions.setSelectedAuditActions(sessionId);

export const setSelectedAuditActions = (sessionId: string): AppThunk => async (dispatch, getState, apollo) => {
  // fetch
  const { data } = await apollo.query({
    query: GET_AUDIT_ACTIONS,
    variables: { notarySessionId: sessionId, limit: 100, pageIndex: 0 },
  });

  const addedAuditActions = selectAuditActions(data.getAuditActionMany.data);
  return dispatch(addedAuditActions);
};

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.auditActions;
export default slice;
