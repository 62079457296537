import { createSlice } from '@reduxjs/toolkit';
import type { Notary, User } from 'dashboard/src/types/user';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from 'dashboard/src/store';

const parseUser = (user) => {
  if (!user) {
    return user;
  }
  //strip empty strings out of
  // replace namespaced auth0 claims keys
  return _.chain({
    auth0Id: user.sub,
    avatar: user.picture,
    email: user.email,
    name: user.name,
    ...user
  })
    .mapKeys((val, key: string) => _.camelCase(key.replace(/.*\.com\//, '')))
    .mapValues((val, key) => {
      if (key === 'sub') {
        return _.slice(val, _.lastIndexOf(val, '|') + 1).join('')
      }

      return val;
    })
    .value() as User;
}

const parseNotary = (notary) => {
  if (!notary) {
    return notary;
  }
  return _.chain({ ...notary }).mapKeys((_val, key: string) => _.camelCase(key)).value()
}

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken: string | null,
  user: User | null;
  permissions: string[];
  orgFeatures: string[];
  organization: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  accessToken: null,
  user: null,
  organization: null,
  permissions: [],
  orgFeatures: [],
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialize(
      state: AuthState,
      action: PayloadAction<{
        isAuthenticated: boolean;
        accessToken: string | null;
        user: User | null;
        permissions: Array<string>;
        orgFeatures: Array<string>;
      }>
    ) {
      state.isInitialised = true;
      state.isAuthenticated = action.payload.isAuthenticated;

      localStorage.setItem('token', action.payload.accessToken);
      state.accessToken = action.payload.accessToken || state.accessToken;
      state.permissions = [...(action.payload.permissions || [])];
      state.orgFeatures = [...(action.payload.orgFeatures || [])];

      state.user = action.payload.user || state.user;
      state.organization =
        action.payload.user?.organizationId ||
        state.user?.organizationId ||
        state.organization;
    },
    login(
      state: AuthState,
      action: PayloadAction<{
        accessToken: string;
        user: User;
        permissions: Array<string>;
        orgFeatures: Array<string>;
      }>
    ) {
      state.isAuthenticated = !!action.payload.user;

      localStorage.setItem('token', action.payload.accessToken);
      state.accessToken = action.payload.accessToken;

      state.user = action.payload.user;
      state.permissions = [...(action.payload.permissions || [])];
      state.orgFeatures = [...(action.payload.orgFeatures || [])];
      state.organization = action.payload.user?.organizationId || null;
    },
    logout(state: AuthState) {
      localStorage.removeItem('token');

      state.permissions = [];
      state.orgFeatures = [];
      state.isAuthenticated = false;
      state.isInitialised = false;
      state.user = null;
      state.organization = null;
      state.accessToken = null;
    },
    setOrganizationId(state: AuthState, action: PayloadAction<{organization: string}>) {
      state.organization = action.payload.organization;
    },
    setToken(state: AuthState, action: PayloadAction<{ accessToken: string}>) {
      localStorage.setItem('token', action.payload.accessToken);
      state.accessToken = action.payload.accessToken;
    },
    updateUser(state: AuthState, action: PayloadAction<{ user: User }>) {
      //Dont store null/undefined
      const updatedUser = { ...state.user, ..._.omitBy(action.payload.user, _.isNil) }
      state.user = updatedUser;
    },
    updateNotary(state: AuthState, action: PayloadAction<{ notary: Notary }>) {
      state.user.notary = { ...state.user?.notary, ...action.payload.notary };
    },
    updatePermissions(state: AuthState, action: PayloadAction<{ permissions: Array<string> }>) {
      const updatedPermissions = action.payload.permissions;
      state.permissions = updatedPermissions;
    },
    updateOrgFeatures(state: AuthState, action: PayloadAction<{ orgFeatures: Array<string> }>) {
      const updatedOrgFeatures = action.payload.orgFeatures;
      state.orgFeatures = updatedOrgFeatures;
    },
  },
});

export const reducer = slice.reducer;

export const initialize = (
  isAuthenticated,
  accessToken,
  user,
  permissions: Array<string> = [],
  orgFeatures: Array<string> = []
) =>
  slice.actions.initialize({
    isAuthenticated,
    accessToken,
    user: parseUser(user),
    permissions,
    orgFeatures,
  });

export const logout = () => slice.actions.logout();

export const login = (
  accessToken: string,
  user: Partial<User>,
  permissions: Array<string> = [],
  orgFeatures: Array<string> = []
) =>
  slice.actions.login({
    accessToken,
    user: parseUser(user),
    permissions,
    orgFeatures,
  });

export const updateUser = (user: User) => slice.actions.updateUser({ user: parseUser(user) })

export const updatePermissions = (permissions: Array<string>) => slice.actions.updatePermissions({ permissions });

export const updateNotary = (notary) => slice.actions.updateNotary({ notary: parseNotary(notary) });

export const setOrganizationId = (organization) => slice.actions.setOrganizationId({ organization });
export const setToken = (accessToken: string) => slice.actions.setToken({ accessToken });
export const selector = (state: RootState) => state.auth;

export default slice;
