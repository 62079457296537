import { gql } from '@apollo/client';

export const LIST_ALL_USERS_QUERY = gql`
  query getMembers($page: Int) {
    getMembers(page: $page) {
      total
      users {
        id
        user_id
        email
        phone
        address1
        address2
        city
        state
        zip
        country
        firstName
        middleName
        lastName
        family_name
        given_name
        roles
        sso
      }
    }
  }
`;

export const GET_ALL_ORG_USERS_QUERY = gql`
  query listUsersByOrgId {
    listUsersByOrgId {
      count
      data {
        id
        firstName
        city
        country
        address1
        address2
        accountActive
        email
        lastName
        middleName
        organizationId
        phone
        roleId
        profileImage
        state
        userType
        username
        zip
        auth0Id
        auth0Roles
        role {
          name
        }
        notary {
          id
          issueDate
          notaryStateId
          commissionId
          commissionState
          dob
          bondId
          primaryCountyId
          secondaryCountyId
          tertiaryCountyId
          bondExpirationDate
        }
      }
    }
  }
`;

export const GET_ALL_USERS_QUERY = gql`
  query findAllUsers(
    $pageIndex: Int = 0
    $limit: Int = 10
    $organizationId: ID!
    $query: String
  ) {
    findAllUsers(
      limit: $limit
      pageIndex: $pageIndex
      orderBy: { createdAt: desc }
      organizationId: $organizationId
      query: $query
    ) {
      count
      data {
        id
        firstName
        city
        country
        address1
        address2
        accountActive
        email
        lastName
        middleName
        organizationId
        phone
        roleId
        profileImage
        state
        userType
        username
        zip
        auth0Id
        auth0Roles
        role {
          name
        }

        notary {
          id
          issueDate
          notaryStateId
          commissionId
          commissionState
          dob
          bondId
          primaryCountyId
          secondaryCountyId
          tertiaryCountyId
          bondExpirationDate
        }
      }
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($orgId: String, $payload: IAddUserInput) {
    createUser(orgId: $orgId, payload: $payload) {
      id
      address1
      address2
      city
      phone
      country
      email
      firstName
      lastName
      state
      zip
    }
  }
`;

export const UPDATE_SELF_MUTATION = gql`
  mutation UpdateSelf($data: UpsertUserInput!) {
    updateSelf(data: $data) {
      id
      address1
      address2
      city
      phone
      country
      email
      firstName
      lastName
      state
      zip
    }
  }
`;

export const UPSERT_USER_MUTATION = gql`
  mutation UpdateUser($user: UpsertUserInput!) {
    upsertUser(user: $user) {
      id
      address1
      address2
      city
      phone
      country
      email
      firstName
      lastName
      state
      zip
    }
  }
`;

export const GET_USER = gql`
  query getUserByAuth0Id($auth0Id: String) {
    getUserByAuth0Id(auth0Id: $auth0Id) {
      id
      firstName
      middleName
      lastName
      address1
      address2
      state
      city
      country
      zip
      roleId
      email
      role {
        id
        descriptiveId
      }
      auth0Roles
      notary {
        id
        notaryStateId
        commissionId
        commissionState
        bondId
        primaryCountyId
        bondExpirationDate
        dob
        issueDate
        notarySignatures {
          type
          image
        }
        notarySeals {
          seal
          expirationDate
        }
        certificates {
          id
          type
        }
      }
      phone
    }
  }
`;

export const GET_ROLES = gql`
  query getAllRoles {
    getRoles {
      id
      name
      description
    }
  }
`;

export const SET_USER_ROLES = gql`
  mutation setUserRoles($auth0Id: String, $auth0Roles: [String]) {
    setUserRoles(auth0Id: $auth0Id, auth0Roles: $auth0Roles)
  }
`;
export const GET_USER_BY_ID = gql`
  query getUserById($id: ID) {
    findOneUser(id: $id) {
      id
      firstName
      middleName
      lastName
      email
      address1
      address2
      state
      city
      country
      zip
      roleId
      role {
        id
        descriptiveId
      }
      roles
      auth0Roles
      notary {
        id
        notaryStateId
        commissionId
        commissionState
        bondId
        primaryCountyId
        bondExpirationDate
        dob
        issueDate
        notarySignatures {
          type
          image
        }
        notarySeals {
          seal
          expirationDate
        }
        certificates {
          id
          type
        }
      }
      phone
      auth0Id
      sso
    }
  }
`;

export const GET_ALL_USERS_DROPDOWN_QUERY = gql`
  query findAllUsers(
    $pageIndex: Int = 0
    $limit: Int = 10
    $organizationId: ID!
    $query: String
  ) {
    findAllUsers(
      limit: $limit
      pageIndex: $pageIndex
      orderBy: { createdAt: desc }
      organizationId: $organizationId
      query: $query
    ) {
      count
      data {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;
