import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_TAGGING_QUEUE = gql`
  subscription ($accessToken: String!) {
    taggingQueue(jwt: $accessToken) {
      id
    }
  }
`;

export const GET_TAGGERS = gql`
  query {
    getTaggers {
      id
      name
      email
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    getAllUsers {
      id
      name
      email
    }
  }
`;

export const ASSIGN_TRANSACTION_TAGGER = gql`
  mutation ($transactionId: String!, $taggerId: String!, $taggerName: String!, $taggerEmail: String!) {
    setTransactionTagger(transactionId: $transactionId, taggerId: $taggerId, taggerName: $taggerName, taggerEmail:$taggerEmail) {
      currentTaggerId
    }
  }
`;

export const UNASSIGN_TRANSACTION_TAGGER = gql`
mutation ($transactionId: String!, $taggerId: String!, $taggerName: String!, $taggerEmail: String!) {
  sendUnAssignTagger(transactionId: $transactionId, taggerId: $taggerId, taggerName: $taggerName, taggerEmail:$taggerEmail) {
    currentTaggerId
    }
  }
`;

export const GET_TAGGING_QUEUE_PAGE = gql`
  query (
    $pagination: PaginationInput!
    $taggerId: String
    $taggerIdIsNot: String
    $mySessionsOnly: Boolean
  ) {
    taggableTransactions: getTaggableTransactions(
      pagination: $pagination
      taggerId: $taggerId
      taggerIdIsNot: $taggerIdIsNot
      mySessionsOnly: $mySessionsOnly
    ) {
      transactions: data {
        id
        sendingOrg {
          id
          name
        }
        currentTaggerId
        participants {
          id
          firstName
          lastName
          primary
          type
          email
          kbaRequired
          credAnalysisRequired
          secondaryIdentificationRequired
          credAnalysisType
          skipVerification
          kbaPassed
          credAnalysisPassed
          kbaTermsAccepted
          authenticationPassed
          authenticationType
        }
        docs: documents {
          id
          title
          status
        }
      }
      total
    }
  }
`;

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation (
    $transactionId: ID!
    $document: DocumentUpdateInput!
    $taggingCompleted: Boolean
  ) {
    updateTags(
      transactionId: $transactionId
      document: $document
      taggingCompleted: $taggingCompleted
    ) {
      id
      title
      description
      order
      type
      status
      sessionType
    }
  }
`;

export const GET_TRANSACTION_QUERY = gql`
  query ($transactionId: String!) {
    getTransaction(id: $transactionId) {
      id
      status
      taggingOrganizationId
      currentTaggerId
      organizationId
      documentParticipants {
        participantId
        documentId
      }
      participants {
        id
        documents {
          documentId
          participantId
          readOnly
          visible
        }
        firstName
        middleName
        lastName
        order
      }
      documents {
        id
        title
        description
        sessionType
        type
        tags
        taggerId
        pages
        gcsRefId
        xfdf
        url
        order
        status
        latestRevision {
          id
          xfdf
          gcsRefId
          version
          completed
          completedAt
        }
      }
    }
  }
`;

export const GET_DOCUMENT_PREVIEWS = gql`
  query ($transactionId: String!) {
    getTransaction(id: $transactionId) {
      documents {
        id
        title
        gcsRefId
        xfdf
        url
      }
    }
  }
`;

export const SEARCH_TAGGING_QUEUE_QUERY = gql`
  query ($query: String!) {
    searchTransactionsInTaggingQueue(query: $query)
  }
`;
