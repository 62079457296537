import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import firebase from 'dashboard/src/lib/firebase';


const FirebaseContext = createContext<typeof firebase>(null);

export function FirebaseProvider({ children }) {

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseContext;
