import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'dashboard/src/theme';
import TopBar from '../LayoutLibrary/TopBar';

interface NavbarLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    marginTop: '64px',
    width: '100%',
  },
}));

const NavbarLayout: FC<NavbarLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div
      role='main'
      className={classes.root}
      data-testid='dashboard'
    >
      <TopBar />
      <div className={classes.wrapper}>
        {children}
      </div>
    </div>
  );
};

NavbarLayout.propTypes = {
  children: PropTypes.node
};

export default NavbarLayout;
