import { lazy, Suspense, useEffect } from 'react';
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import useAuth from 'dashboard/src/hooks/useAuth';
import LoadingScreen from 'dashboard/src/components/LoadingScreen';

export const LDProvider = lazy(async () => {
  const LDClient = await asyncWithLDProvider({
    clientSideID: process.env.NX_LAUNCH_DARKLY_API_KEY,
    user: { anonymous: true },
    options: {
      allAttributesPrivate: true,
      bootstrap: {
        gqlPollingInterval: 30000,
        disableEqCheckPreview: false,
        disableRonCorePreview: false,
        disableDashboardPreview: false,
        disableKbaRunOrderForm: false,
        disableCredentialAnalysisRunOrderForm: false,
        taggingQueueDebounce: 250,
        enloDashboardPdftronServer: false
      }
    },
  })

  return { default: LDClient };
});
// // // This updates LaunchDarkly to let it know who the currently logged in user is
export const LDIdentityUpdater = ({ children }) => {
  const ldClient = useLDClient();
  const { user } = useAuth();

  // use uuid orgid not the auth0 if available
  const organizationId = user?.enlOrganizationId || user?.organizationId;
  useEffect(() => {
    (window as any).ldClient = ldClient;
    if (organizationId) {
      ldClient.identify({
        key: organizationId,
      });
    } else {
      ldClient.identify({ key: 'unauthorized' }, null);
    }
  }, [ldClient, organizationId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const LDIdentity = ({ children }) =>
  <Suspense fallback={<LoadingScreen />}>
    <LDProvider><LDIdentityUpdater>{children}</LDIdentityUpdater></LDProvider>
  </Suspense>;


