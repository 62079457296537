import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import _ from "lodash";
import { DashboardPalette } from "dashboard/src/components/WhiteLabel/DashboardStyle/DashboardStyleForm";
import { ENL_LOGO } from "dashboard/src/constants";
import { GET_ORG_THEME, SET_ORG_LOGO, UPDATE_DASHBOARD_THEME_SETTINGS } from "dashboard/src/lib/gql/theme";
import { AppThunk, RootState } from "dashboard/src/store";
import { stripDataUrl } from "dashboard/src/utils/filetoBase64";


interface ThemeState {
  name: string
  logo: string
  uploadedLogo: boolean
  theme: DashboardPalette
  previewMode: boolean
  previewTheme: DashboardPalette
  previewLogo: string
};

interface DashboardThemeSettings {
  name: string
  logo?: string
  uploadedLogo: boolean
  themeColors: DashboardPalette
}


export const initialState: ThemeState = {
  name: 'LIGHT',
  logo: ENL_LOGO,
  theme: {},
  uploadedLogo: false,
  previewMode: false,
  previewTheme: {},
  previewLogo: ENL_LOGO
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (state: ThemeState, action: PayloadAction<{ themeName: string, theme: DashboardPalette, logo: string, uploadedLogo: boolean }>) => {
      const { themeName, theme, logo, uploadedLogo } = action.payload;
      state.name = themeName;
      state.theme = theme;
      state.logo = logo;
      state.previewLogo = logo;
      state.uploadedLogo = uploadedLogo;
    },
    setPreviewMode: (state: ThemeState, action: PayloadAction<{ previewMode: boolean }>) => {
      const { previewMode } = action.payload;
      state.previewMode = previewMode;
    },
    setPreviewTheme: (state: ThemeState, action: PayloadAction<{ theme: DashboardPalette }>) => {
      const { theme } = action.payload;
      state.previewTheme = theme;
    },
    setPreviewLogo: (state: ThemeState, action: PayloadAction<{ previewLogo: string }>) => {
      const { previewLogo } = action.payload;
      state.previewLogo = previewLogo;
    }
  }
});




export const changeTheme = (themeName: string, theme: DashboardPalette, logo: string, uploadedLogo: boolean): Action => slice.actions.changeTheme({ themeName, theme, logo, uploadedLogo })
export const setPreviewTheme = (theme: DashboardPalette): Action => slice.actions.setPreviewTheme({ theme })
export const setPreviewMode = (previewMode: boolean): Action => slice.actions.setPreviewMode({ previewMode })
export const setPreviewLogo = (previewLogo: string): Action => slice.actions.setPreviewLogo({ previewLogo })


export const getTheme = (): AppThunk => async (dispatch, getState, apollo) => {
  const { auth } = getState();

  if (!auth?.user?.organizationId) {
    return
  }

  const { data } = await apollo.query({
    query: GET_ORG_THEME,
    context: { service: 'enl-graphql' },
    variables: { organization_id: auth.user.organizationId },
    fetchPolicy: 'no-cache'
  });

  const { getOrganizationInfo: { settings } } = data;
  const dashboardThemeSettings = ((settings || []).find((s) => s.key ==='dashboardThemeSettings') || {}).value || {};
  const uploadedLogo = dashboardThemeSettings.uploadedLogo;
  const logoUrl = uploadedLogo ? `/organization/${auth.user.organizationId}/logo` : ENL_LOGO
  return dispatch(changeTheme(dashboardThemeSettings.name, dashboardThemeSettings.themeColors, logoUrl, uploadedLogo));
}


export const getThemeAsPreview = (): AppThunk => async (dispatch, getState, apollo) => {
  const { adminConsole, theme } = getState();

  const { data } = await apollo.query({
    query: GET_ORG_THEME,
    context: { service: 'enl-graphql' },
    variables: { organization_id: adminConsole.organizationId },
    fetchPolicy: 'no-cache'
  });

  const { getOrganizationInfo: { settings } } = data;
  const dashboardThemeSettings = ((settings || []).find((s) => s.key ==='dashboardThemeSettings') || {}).value || {};
  const logoUrl = dashboardThemeSettings.uploadedLogo ? `/organization/${adminConsole.organizationId}/logo` : ENL_LOGO
  if (theme.previewMode) {
    dispatch(setPreviewTheme({ ...dashboardThemeSettings.themeColors, ...theme.previewTheme }));
  } else {
    dispatch(setPreviewTheme({ ...dashboardThemeSettings.themeColors }));
  }
  return dispatch(setPreviewLogo(logoUrl));
}



export const savePreviewAsTheme = (): AppThunk => async (dispatch, getState, apollo) => {
  const { auth, theme, adminConsole } = getState();
  const themeName = _.isEmpty(theme.previewTheme) ? 'LIGHT' : 'ORG_STYLE'
  const uploadedLogo = theme.previewLogo !== ENL_LOGO

  const dashboardThemeSettings: DashboardThemeSettings = {
    name: themeName,
    themeColors: theme.previewTheme,
    uploadedLogo
  }

  // Mutate the logo with the base 64 data OR remove the logo from the database by setting the entry to null
  await apollo.mutate({
    mutation: SET_ORG_LOGO,
    context: { service: 'enl-graphql' },
    variables: { organizationId: adminConsole.organizationId, base64: uploadedLogo ? stripDataUrl(theme.previewLogo) : null }
  });

  await apollo.mutate({
    mutation: UPDATE_DASHBOARD_THEME_SETTINGS,
    context: { service: 'enl-graphql' },
    variables: { organizationId: adminConsole.organizationId, value: dashboardThemeSettings }
  });

  if (adminConsole.organizationId === auth.user.organizationId) {
    return dispatch(changeTheme(themeName, theme.previewTheme, theme.previewLogo, uploadedLogo));
  }
}

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.theme;
export default slice;

