import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  LIST_ALL_USERS_QUERY,
  GET_ALL_USERS_DROPDOWN_QUERY,
  GET_USER_BY_ID,
  GET_USER,
} from '@enotarylog/gql/user';
import { AppThunk, RootState } from 'dashboard/src/store';
import { User } from 'dashboard/src/types/user';
import { IndexedById } from 'dashboard/src/types/paginated';

interface IPaginationOptions {
  limit?: number;
  page?: number;
}
interface UserState {
  users: IndexedById<User>;
}

export const initialState: UserState = {
  users: {
    byId: {},
    allIds: [],
    total: 0,
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUsers: (
      state: UserState,
      action: PayloadAction<{ total: number; users: Record<string, User> }>
    ) => {
      const { users, total } = action.payload;

      state.users.byId = Object.assign({}, state.users.byId, { ...users });
      state.users.allIds = [...state.users.allIds, ...Object.keys(users)];
      state.users.total = total;
    },
  },
});

export const addUsers = (total: number, userArr: Array<User>): Action =>
  slice.actions.addUsers({
    total,
    users: _.chain(userArr)
      .reduce(
        (acc, user) => ({
          ...acc,
          [user.id ?? user.user_id]: user,
        }),
        {}
      )
      .value(),
  });

export const getUsers =
  (page: number): AppThunk =>
    async (dispatch, getState, apollo) => {
      const { data } = await apollo.query({
        query: LIST_ALL_USERS_QUERY,
        context: { service: 'enl-graphql' },
        fetchPolicy: 'network-only',
        variables: { page },
      });

      const { getMembers } = data;
      return dispatch(addUsers(getMembers.total, getMembers.users));
    };

export const getUserByAuthID =
  (auth0Id?: string): AppThunk => async (dispatch, getState, apollo) => {
    const { data } = await apollo.query({
      query: GET_USER,
      context: { service: 'enl-graphql' },
      fetchPolicy: 'network-only',
      variables: { auth0Id },
    });

    const { getUserByAuth0Id } = data;
    return dispatch(addUsers(getUserByAuth0Id.total, getUserByAuth0Id.users));
  };

export const getUsersForDropdown =
  (pagination: IPaginationOptions = { limit: 20, page: 0 }): AppThunk =>
    async (dispatch, getState, apollo) => {
      const { organizationId } = getState().auth.user;

      const { data } = await apollo.query({
        query: GET_ALL_USERS_DROPDOWN_QUERY,
        context: { service: 'enl-graphql' },
        variables: {
          limit: pagination.limit || 10,
          pageIndex: pagination.page,
          organizationId,
        },
        fetchPolicy: 'no-cache',
      });

      const {
        findAllUsers: { data: users, count },
      } = data;
      return dispatch(addUsers(count, users));
    };

export const getUserById =
  (id): AppThunk =>
    async (dispatch, getState, apollo) => {
      const {
        data: { findOneUser },
      } = await apollo.query({
        query: GET_USER_BY_ID,
        variables: { id },
        fetchPolicy: 'no-cache',
      });
      return dispatch(addUsers(1, [findOneUser]));
    };

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.users;
export default slice;
