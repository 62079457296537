import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selector } from 'dashboard/src/slices/auth';

export const permissionsSel = createSelector(selector, (auth) => auth.permissions);

// Examples:
// const can = useCan();
// can('read:organization', 'read:users') // Returns true only if the current user has both of those permissions
// can.all('read:organization', 'read:users') // Exactly the same as above
// can.any('read:organization', 'read:organizations') // Returns true if the current user has at least one of those permissions
// // You can also combine these together with regular JS:
// can.any('read:organization', 'read:organizations') && can('read:users') // Requires the user (can read:organization or read:organizations) and the user can read:users

export type CanFunction = (...permissions: string[]) => boolean;
export type Can = CanFunction & {
  any: CanFunction;
  all: CanFunction;
};

const useCan = (): Can => {
  const permissions = useSelector(permissionsSel);
  const hasPermission = useCallback((permission: string) => permissions.includes(permission), [permissions]);
  const canDoAll = useCallback((...requiredPermissions: string[]) => requiredPermissions.every(hasPermission), [hasPermission]);
  const canDoSome = useCallback((...desiredPermissions: string[]) => desiredPermissions.some(hasPermission), [hasPermission]);

  const can = canDoAll as Can;
  can.any = canDoSome;
  can.all = canDoAll;
  return can;
};
export default useCan;
