import React, { createContext } from 'react'
import useFirebase, { FirebaseContext } from 'dashboard/src/hooks/useFirebase';

import useAuth, { AuthContext } from 'dashboard/src/hooks/useAuth';
import _ from 'lodash';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router';
import io from 'socket.io-client';



const PresenceContext = createContext({})


interface PresenceUser {
  sid: string;
  pathname: string;
  organizationId: string;
  userId: string;
}

class PresenceProviderFn extends React.Component<{
  location: ReturnType<typeof useLocation>
  history: ReturnType<typeof useHistory>
  auth: ReturnType<typeof useAuth>
  firebase: ReturnType<typeof useFirebase>
}> {


  initialized = false;
  socket: ReturnType<typeof io>;
  state = {
    users: {}
  }

  componentDidMount(){
    console.debug('presence context initialized', this.initialized);
    const socket = this.initializeSocket();
    socket.connect();
    this.initFirebasePresence();
    this.initListenPage();
  }


  initializeSocket = () => {
    const socket = this.socket = io('/org-presence', {
      path: '/api/websocket',
      autoConnect: false,
      transports: ['websocket'],
      reconnection: true,
      auth: {
        pathname: this.props.location.pathname,
        organizationId: _.get(this.props,'auth.user.enlOrganizationId'),
        userId: _.get(this.props, 'auth.user.enlUserId'),
      },
    });

    // listen for route changes and emit update to all other org users
    socket
      .on('connect', () => {
        this.props.history.listen((location) => {
          socket.emit('update-pathname', { pathname: location.pathname });
        });
      })
      .on('set-users', (users: PresenceUser[]) => this.setState({ users: _.keyBy(users, 'sid') }))
      .on('user-joined', (user: PresenceUser) => this.setState({
        users: {
          ...this.state.users,
          [user.sid]: user
        }
      }))
      .on('user-update', (user: PresenceUser) => this.setState({
        users: {
          ...this.state.users,
          [user.sid]: user
        }
      }))
      .on('user-remove', (user: PresenceUser) => this.setState({
        users: _.omit(this.state.users, [user.sid])
      }));

    (window as any).socket = socket;
    this.initialized = true;
    return socket;
  }


  initFirebasePresence = async () => {
    const { auth, firebase, location } = this.props;
    if (!auth?.user?.enlUserId || !auth?.user?.enlOrganizationId) {
      return;
    }
    const connRef = firebase.database().ref('.info/connected');
    const orgRef = firebase.database().ref(`/organization/${auth.user.enlOrganizationId}`);
    const notaryRef = orgRef.child('notaries');
    const pRef = notaryRef.child(auth.user.enlUserId.replace(/\./g, ''));

    connRef.on('value', async (snapshot) => {
      if (snapshot.val() === false) {
        console.debug('%cfirebase not connected 🔥!', 'color: red; font-size:20px');

        return;
      }

      console.debug('%cfirebase connected 🔥!', 'color: blue; font-size:20px');

      try {
        await pRef
          .onDisconnect()
          .set({});

        const presenceState = _.pickBy({
          page: location.pathname.replace(/(.*)\//gm, ''),
          connected: true,
        }, _.identity);

        await pRef.set(presenceState);

      } catch (error) {
        console.error(error);
      }

    });


  }

  initListenPage = async () => {
    const { auth, firebase } = this.props;

    this.props.history.listen((location) => {
      if (!auth?.user?.enlUserId || !auth?.user?.enlOrganizationId) {
        return;
      }

      const orgRef = firebase.database().ref(`/organization/${auth.user.enlOrganizationId}`);
      const notaryRef = orgRef.child('notaries');
      const pRef = notaryRef.child(auth.user.enlUserId.replace(/\./g, ''));
      pRef.child('page').set(location.pathname.replace(/(.*)\//gm, ''))
    });
  }

  componentWillUnmount(): void {
    if (this.initialized && this.socket)  {
      this.socket.close();
      this.socket = null;
    }

    console.debug('presence context unmounting', this.initialized);
  }

  render(){
    return (
      <PresenceContext.Provider
        value={{
          socket: this.socket,
          presence: this.state.users
        }}
      >
        {this.props.children}
      </PresenceContext.Provider>
    )

  }
}





class PresenceProviderBase extends React.Component<RouteComponentProps> {
  render(){
    return (
      <AuthContext.Consumer>
        {(auth) => (
          <FirebaseContext.Consumer>
            {(firebase) => (
              <PresenceProviderFn
                location={this.props.location}
                history={this.props.history}
                firebase={firebase}
                auth={auth}
              >
                {this.props.children}
              </PresenceProviderFn>
            )}
          </FirebaseContext.Consumer>
        )}
      </AuthContext.Consumer>
    )
  }
}

export const PresenceProvider = withRouter(PresenceProviderBase)

export default PresenceContext;
