import React, { Suspense, Fragment, lazy, FC } from 'react';
import { Switch, Redirect, Route as RouterRoute } from 'react-router-dom';
import DashboardLayout from 'dashboard/src/layouts/DashboardLayout';
import MainLayout from 'dashboard/src/layouts/MainLayout';
import NavbarLayout from 'dashboard/src/layouts/NavbarLayout';
import LoadingScreen from 'dashboard/src/components/LoadingScreen';
import AuthGuard from 'dashboard/src/components/AuthGuard';
import GuestGuard from 'dashboard/src/components/GuestGuard';
import FeatureGuard from 'dashboard/src/components/FeatureGuard';
import PasswordResetGuard from 'dashboard/src/components/PasswordResetGuard';
import useCan from 'dashboard/src/hooks/useCan';
import PermissionGuard from 'dashboard/src/components/PermissionGuard';

import { SITEMAP } from 'dashboard/src/constants';
import { PresenceProvider } from './contexts';

interface Route {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  wrapper?: any;
  layout?: any;
  component?: any;
  routes?: Route[];
};

// TODO: use SITEMAP instead of hard-coded URL's
export const routes: Route[] = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to='/portal' />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('dashboard/src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('dashboard/src/views/errors/ForbiddenView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('dashboard/src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/idp',
    component: lazy(() => import('dashboard/src/views/idp'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/portal',
    component: lazy(() => import('dashboard/src/views/auth/PortalView'))
  },
  // in the login flow the GuestGuard will redirect before rendering the CallbackView if the user isAuthenticated. the handleRedirectCallback is not called
  {
    exact: true,
    guard: GuestGuard,
    path: '/callback',
    component: lazy(() => import('dashboard/src/views/auth/CallbackView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('dashboard/src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('dashboard/src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('dashboard/src/views/auth/RegisterView'))
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/transaction/:id/session/:nsId',
    component: lazy(() => import('dashboard/src/views/ron/session-details'))
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/enterprise/documents/edit-notarization/:nsId',
    component: lazy(() => import('dashboard/src/views/ron/edit-notarization'))
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/enterprise/notary-queue',
    component: lazy(() => import('dashboard/src/views/ron/notary-queue'))
  },
  {
    layout: NavbarLayout,
    guard: AuthGuard,
    wrapper: PresenceProvider,
    path: '/app/templating/create-new-template',
    component: lazy(() => import('dashboard/src/views/templating/CreateNewTemplate'))
  },
  {
    layout: NavbarLayout,
    guard: AuthGuard,
    wrapper: PresenceProvider,
    path: '/app/templating/:id/edit',
    component: lazy(() => import('dashboard/src/views/templating/EditTemplate'))
  },
  {
    path: '/',
    guard: AuthGuard,
    wrapper: PresenceProvider,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/enterprise/room/:id',
        component: lazy(() => import('dashboard/src/views/ron/notary-room'))
      },
      {
        exact: true,
        path: '/app/profile',
        component: lazy(() => import('dashboard/src/views/profile/ProfileView'))
      },
      {
        exact: true,
        path: '/app/profile/notary-ejournal/:sessionId',
        component: lazy(() => import('dashboard/src/views/NotaryJournal/NotaryJournalDetail'))
      },
      {
        exact: true,
        path: '/app/profile/notary-ejournal/document-retrieval/:sessionId',
        component: lazy(() => import('dashboard/src/views/NotaryJournal/DocumentRetrieval/DocumentRetrievalDetails'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('dashboard/src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/queue',
        component: lazy(() => import('dashboard/src/views/queue/KanbanView'))
      },
      {
        exact: true,
        path: '/app/esignature-dashboard',
        component: lazy(() => import('dashboard/src/views/esignature/EsignatureListView'))
      },
      {
        exact: true,
        path: '/app/esignature-dashboard/:sessionId',
        component: lazy(() => import('dashboard/src/views/esignature/EsignDetails'))
      },
      {
        exact: true,
        path: '/app/esignature-dashboard/:sessionId/details',
        component: lazy(() => import('dashboard/src/views/esignature/EsignDetails'))
      },
      {
        exact: true,
        path: `${SITEMAP.eSignOrders.path}`,
        component: lazy(() => import('dashboard/src/views/order'))
      },
      {
        exact: true,
        path: `${SITEMAP.ronOrders.path}` + '/:id',
        component: lazy(() => import('dashboard/src/views/ron/completed-documents'))
      },
      {
        exact: true,
        path: `${SITEMAP.ronOrders.path}`,
        component: lazy(() => import('dashboard/src/views/ron'))
      },
      {
        exact: true,
        path: `${SITEMAP.ronNotaryQueue.path}`,
        component: lazy(() => import('dashboard/src/views/ron/notary-queue'))
      },
      {
        exact: true,
        path: `${SITEMAP.createNewOrder.path}`,
        component: lazy(() => import('dashboard/src/views/order/new-order')),
        guard: FeatureGuard('createNewOrderButton'),
      },
      {
        exact: true,
        path: `${SITEMAP.viewExistingOrder.path}`,
        component: lazy(() => import('dashboard/src/views/esignature/EsignDetails'))
      },
      {
        exact: true,
        path: `${SITEMAP.editExistingOrder.path}`,
        component: lazy(() => import('dashboard/src/views/order/edit-order'))
      },
      {
        exact: true,
        path: `${SITEMAP.ronTaggingQueue.path}`,
        component: lazy(() => import('dashboard/src/views/ron/tagging-queue'))
      },
      {
        exact: true,
        path: `${SITEMAP.eSignTaggingQueue.path}`,
        component: lazy(() => import('dashboard/src/views/tagging/TaggingQueue'))
      },
      {
        exact: true,
        path: `${SITEMAP.eSignTaggingQueue.path}/:transactionId`,
        component: lazy(() => import('dashboard/src/views/tagging/TaggingRoom'))
      },
      {
        exact: true,
        path: '/app/notary-room/signer',
        component: lazy(() => import('dashboard/src/views/notary-room/SignerNotaryRoomView'))
      },
      {
        exact: true,
        path: '/app/notary-room/:nsId',
        component: lazy(() => import('dashboard/src/views/notary-room/NotaryRoomView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to='/app/mail/all' />
      },
      {
        exact: true,
        path: '/app/management/users',
        component: lazy(() => import('dashboard/src/views/ron/users')),
        guard: FeatureGuard('usersPage'),
      },
      {
        exact: true,
        path: '/app/management/users/:id',
        component: lazy(() => import('dashboard/src/views/ron/user-edit')),
        guard: FeatureGuard('usersPage'),
      },
      {
        exact: true,
        path: '/app/management/users/:userId/edit',
        component: lazy(() => import('dashboard/src/views/user/UserEditView')),
        guard: FeatureGuard('usersPage'),
      },
      {
        exact: true,
        path: '/app/account/notary-ejournal/:id',
        component: lazy(() => import('dashboard/src/views/ron/ejournal-details')),
        guard: FeatureGuard('usersPage'),
      },
      // management
      {
        exact: true,
        path: '/app/management/organizations',
        component: lazy(() =>
          import('dashboard/src/views/organization/OrganizationListView')
        )
      },
      {
        exact: true,
        path: '/app/management/organizations/:id/edit',
        component: lazy(() =>
          import('dashboard/src/views/organization/OrganizationEditView')
        )
      },
      {
        exact: true,
        path: '/app/management/organizations',
        component: lazy(() =>
          import('dashboard/src/views/organization/OrganizationListView')
        )
      },
      {
        exact: true,
        path: '/app/management/my-organization',
        guard: PermissionGuard({
          featureGates: ['ORG_MANAGEMENT'],
          resources: ['EDIT_ORG_SETTINGS'],
        }),
        component: lazy(() =>
          import('dashboard/src/views/my-organization/MyOrganizationView')),
      },
      // documents
      {
        exact: true,
        path: '/app/management/documents',
        component: lazy(() => import('dashboard/src/views/document/DocumentListView'))
      },
      {
        exact: true,
        path: '/app/management/documents/:sessionId',
        component: lazy(() => import('dashboard/src/views/document/DocumentDetailsView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('dashboard/src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: `${SITEMAP.signingOrder.path}`,
        component: lazy(() => import('dashboard/src/views/reports/NotaryLoopDashboard'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to='/app/reports/dashboard' />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to='/app/reports/dashboard' />
      },
      {
        exact: true,
        path: '/app/ron/tagging-queue/:id',
        component: lazy(() => import('dashboard/src/views/ron/tagging-room'))
      },
      {
        exact: true,
        path: '/app/ron/notary-queue/:id',
        component: lazy(() => import('dashboard/src/views/ron/notary-room'))
      },
      {
        exact: true,
        path: '/app/ron/notary-queue/edit-notarization/:nsId',
        component: lazy(() => import('dashboard/src/views/ron/edit-notarization'))
      },
      {
        exact: true,
        path: '/app/ron/orders/edit-notarization/:nsId',
        component: lazy(() => import('dashboard/src/views/ron/edit-notarization'))
      },
      {
        exact: true,
        path: '/app/reports/signingOrder/:nsId/new-notarization/doc-upload',
        component: lazy(() => import('dashboard/src/views/ron/new-signing-order'))
      },
      {
        exact: true,
        path: '/app/ron/orders/new-notarization/doc-upload',
        component: lazy(() => import('dashboard/src/views/ron/new-notarization'))
      },
      {
        exact: true,
        path: `${SITEMAP.notaryLoop.path}`,
        component: lazy(() => import('dashboard/src/views/notary-loop'))
      },
      // #region Templating Routes
      {
        exact: true,
        path: SITEMAP.templateManage.path,
        component: lazy(() => import('dashboard/src/views/templating/TemplateManagement')),
        guard: PermissionGuard({
          featureGates: ['TEMPLATING'],
          resources: ['VIEW_TEMPLATE'],
        })
      },
      {
        exact: true,
        path: SITEMAP.templateRoles.path,
        component: lazy(() => import('dashboard/src/views/templating/TemplateRoles')),
        guard: PermissionGuard({
          featureGates: ['TEMPLATING'],
          resources: ['MANAGE_TEMPLATE_ROLES'],
        })
      },
      // #endregion
      {
        component: () => <Redirect to='/404' />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: PasswordResetGuard,
        path: '/',
        component: () => <Redirect to='/portal' />
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('dashboard/src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  },

];

export const RoutesView: FC<{
  routes: (Route | null)[],
}> = ({ routes }) => {
  const can = useCan();
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes
          .map((route: Route, i: number) => {

            const Guard = route.guard || Fragment;
            const Wrapper = route.wrapper || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;
            return (
              <RouterRoute
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (route.path === `${SITEMAP.signingOrder.path}` && !can('VIEW_SIGNING_ORDERS')) ? <Redirect to='/404' /> : (
                  <Guard>
                    <Wrapper>
                      <Layout>
                        {
                          route.routes ?
                            <RoutesView routes={route.routes} />
                            : (<Component {...props} />)
                        }
                      </Layout>
                    </Wrapper>
                  </Guard>
                )}
              />
            );
          })}
      </Switch>
    </Suspense>
  )};
