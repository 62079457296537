import React, { useEffect } from 'react';
import type { ReactNode } from 'react';
import PropTypes from 'prop-types';
import useQuery from '../hooks/useQuery';
import useAuth from '../hooks/useAuth';
import { CALLBACK_ORG_ID } from "../constants";

interface PasswordResetGuardProps {
  children: ReactNode;
}

// This guard is used so the root route can handle if a Auth0 password reset was performed or not.
const PasswordResetGuard = ({ children }: PasswordResetGuardProps) => {
  const queryHandler = useQuery();
  const { setOrganization } = useAuth();

  useEffect(() => {
    /* When Auth0 redirects after a password reset, it always append a "success" indicator.
    Checking for that indicator and a value of "true" allows to infer that a password reset was
    successfully performed. */
    if (queryHandler.has("success") && queryHandler.get("success") === "true") {
      const orgID = localStorage.getItem(CALLBACK_ORG_ID);

      if (orgID !== null) {
        /* We need to save the callback organization ID in state so that we can redirect directly
        into the Auth0 login page. */
        localStorage.removeItem(CALLBACK_ORG_ID);
        setOrganization(orgID);
      }
    }
  }, [queryHandler, setOrganization]);

  return children;
};

PasswordResetGuard.propTypes = {
  children: PropTypes.node
};

export default PasswordResetGuard;
