export const auth0Config = {
  client_id: process.env.NX_AUTH0_CLIENT_ID,
  domain: process.env.NX_AUTH0_DOMAIN,
  audience: process.env.NX_AUTH0_AUDIENCE,
  scope: process.env.NX_AUTH0_SCOPE,
  advancedOptions: {
    // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
    defaultScope: process.env.NX_AUTH0_SCOPE
  },
  useRefreshTokens: true
};

export const firebaseConfig = {
  apiKey: process.env.NX_FIREBASE_API_KEY,
  appId: process.env.NX_FIREBASE_APP_ID,
  authDomain: process.env.NX_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NX_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.NX_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.NX_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NX_FIREBASE_STORAGE_BUCKET
};
