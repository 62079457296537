import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface FeatureGuardProps {
  children?: ReactNode;
}

const FeatureGuard: (feature: string) => FC<FeatureGuardProps> = (feature: string) => ({ children }) => {
  const flags = useFlags(); // eslint-disable-line
  /**
   * If you reload a page, `flags` will be `undefined`. (It takes some time
   * to fetch the feature flags from LaunchDarkly!) If `flags` is `undefined`,
   * we simply return `null` instead of redirecting to the 404 page so this page
   * can be reloaded once the feature flags are available.
   */
  if (!flags) {
    return null;
  }

  /**
   * We strictly check that the flag is `false`, because (as I found out the
   * hard way), LaunchDarkly can also _partially_ load the flags, so this
   * particular field may be `undefined`, even though the whole `flags` object
   * is present.
   */
  if (flags[feature] === false) {
    return <Redirect to='/404' />;
  }

  return <>{children}</>;
};

export default FeatureGuard;
