import { gql } from "@apollo/client";

export const GET_AUDIT_ACTIONS = gql`
  # Write your query or mutation here
  query getAuditActions($limit: Int!, $pageIndex: Int!, $notarySessionId: ID!) {
    getAuditActionMany(notarySessionId: $notarySessionId, limit: $limit, pageIndex: $pageIndex) {
      data {
        createdAt,
        datum,
        user {
          firstName,
          middleName,
          lastName
          role {
            name
          }
        }
        notarySessionUser {
          user {
            firstName
            middleName,
            lastName
          }
          type
        }
        notarySession {
          id
        },
        label
      }
      count
      pageIndex
    }
  }
`;


export const GET_ESIGN_AUDIT_ACTIONS = gql`
  query getEsignAuditActions($transactionId: ID!) {
    getAudits(transactionId: $transactionId) {
      entity
      event
      entityId
      createdAt   
      data
    }
  }
`;
