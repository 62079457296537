import { gql } from "@apollo/client";

export const GET_ORGANIZATION_INFO = gql`
  query ($auth0OrganizationId: ID!) {
    getOrganizationInfo(id: $auth0OrganizationId) {
      address1
      address2
      city
      email
      id
      name
      phone
      state
      zip
    }
  }
`;

export const GET_ORGANIZATION_ID_FROM_NAME = gql`
  query ($name: String!) {
    getOrganizationIdFromName(name: $name)
  }
`;

export const GET_ALL_ORGANIZATIONS_QUERY = gql`
  query findAllOrganization($pageIndex: Int = 10, $limit: Int = 10) {
    findAllOrganization(pageIndex: $pageIndex, limit: $limit, orderBy: {createdAt: desc}) {
      count
      pageIndex
      data {
        id
        name
        display_name
        metadata {
          address1
          address2
          city
          state
          zip
          phone
          email
        }
        branding {
          colors {
            page_background
            primary
          }
          logo_url
        }
      }
    }
  }
`;

export const GET_ALL_ORGANIZATIONS_DROPDOWN_QUERY = gql`
  query findAllOrganization($pageIndex: Int = 10, $limit: Int = 10) {
    findAllOrganization(pageIndex: $pageIndex, limit: $limit, orderBy: {createdAt: desc}) {
      count
      pageIndex
      data {
        id
        name
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  # Write your query or mutation here
  query getOrganizationSettings($auth0OrganizationId: ID!) {
    getOrganizationSettings(auth0OrganizationId: $auth0OrganizationId) {
      facialRecognition {
        passBase {
          enable
        }
        eqCheckWebCam {
          enable
        }
      }
      kbaProviders {
        authenticating {
          order
        }
        lexisNexis {
          order
        }
      }
      credentialAnalysisProviders {
        jumio {
          order
        }
        passbase {
          order
        }
      }
      style
      auth0OrganizationId
    }
    getOrganization {
      id
        name
        display_name
        metadata {
          address1
          address2
          city
          state
          zip
          phone
          email
        }
    }
    getOrganizationConfiguration(auth0OrganizationId: $auth0OrganizationId) {
      facialRecognition {
        passBase {
          enable
        }
        eqCheckWebCam {
          enable
        }
      }
      kbaProviders {
        authenticating {
          enable
        }
        lexisNexis {
          enable
        }
      }
      credentialAnalysisProviders {
        passbase {
          enable
        }
        jumio {
          enable
        }
      }
      branding {
        signerJourney {
          enable
        }
        orgDashboard {
          enable
        }
      }
    }
  }
`;

// NOTE: Use these for settings updates
export const UPDATE_KBA_PROVIDERS = gql`
  mutation setKbaProviders($organizationId: ID!, $value: KbaProvidersInput!) {
    setKbaProviders(organizationId: $organizationId, value: $value) {
      authenticating {
        order
      }
      lexisNexis {
        order
      }
    }
  }
`;

export const UPDATE_CRED_PROVIDERS = gql`
  mutation setCredentialAnalysisProviders($organizationId: ID!, $value: CredentialAnalysisProvidersInput!) {
    setCredentialAnalysisProviders(organizationId: $organizationId, value: $value) {
      passbase {
        order
      }
      jumio {
        order
      }
    }
  }
`;

export const UPDATE_FACIAL_RECOGNITION = gql`
  mutation setFacialRecognition($organizationId: ID!, $value: FacialRecognitionInput!) {
    setFacialRecognition(organizationId: $organizationId, value: $value) {
      passBase {
      enable
    }
    eqCheckWebCam {
      enable
    }
  }
}
`;

export const UPDATE_BRANDING = gql`
  mutation setBranding($organizationId: ID!, $value: Branding!) {
    setBranding(organizationId: $organizationId, value: $value) {
      signerJourney {
      enable
    }
    orgDashboard {
      enable
    }
  }
}
`;

// NOTE: Use these for configuration updates
export const UPDATE_KBA_PROVIDERS_CONFIG = gql`
  mutation updateKbaProvidersConfig(
  $organizationId: ID!
  $value: KbaProvidersInputConfiguration!
  ) {
    setKbaProvidersConfiguration(organizationId: $organizationId, value: $value) {
      authenticating {
        enable
      }
      lexisNexis {
        enable
      }
    }
  }
`

export const UPDATE_CRED_PROVIDERS_CONFIG = gql`
  mutation updateCredProvidersConfig(
  $organizationId: ID!
  $value: CredentialAnalysisProvidersInputConfiguration!
  ) {
    setCredentialAnalysisProvidersConfiguration(
    organizationId: $organizationId
    value: $value
    ) {
      jumio {
        enable
      }
      passbase {
        enable
      }
    }
  }
`

export const UPDATE_FACIAL_RECOG_CONFIG = gql`
  mutation updateFacialRecogConfig($organizationId: ID! $value: FacialRecognitionInputConfiguration!) {
    setFacialRecognitionConfiguration(organizationId: $organizationId value: $value) {
      passBase {
        enable
      }
      eqCheckWebCam {
        enable
      }
    }
  }
`

export const UPDATE_STYLE = gql`
  mutation updateStyle($organizationId: ID! $value: JSONObject!) {
    setStyle(organizationId: $organizationId value: $value)
  }
`;

export const UPDATE_BRANDING_CONFIG = gql`
  mutation updateBrandingConfig($organizationId: ID! $value: BrandingInputConfiguration!) {
    setBrandingConfiguration(organizationId: $organizationId value: $value) {
      signerJourney {
        enable
      }
      orgDashboard {
        enable
      }
    }
  }
`;

export const GET_CUSTOM_TAGS = gql`
  query ($organizationId: ID!) {
    getCustomTags(organizationId: $organizationId)
  }
`;
