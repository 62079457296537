import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import bPromise from 'bluebird';
import { Auth0Client, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import type { User } from '../types/user';
import SplashScreen from '../components/SplashScreen';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import {
  initialize,
  login,
  logout,
  selector as authSelector,
  setOrganizationId,
} from '../slices/auth';
import { getOrganizationFromUrl } from 'dashboard/src/utils/getOrganizationFromUrl';
import { parseJwt } from 'dashboard/src/utils/parseToken';
import axios from 'axios';

export interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken?: string | null;
  user: User | null;
  organization: string | null;
}

export interface AuthContextValue extends AuthState {
  method: 'Auth0';
  client?: Auth0Client;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  handleRedirectCallback: (options?: any) => Promise<void>;
  logout: () => void;
  setOrganization: (organization?: string) => void;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  organization: null,
  method: 'Auth0',
  loginWithRedirect: (_options: RedirectLoginOptions) => Promise.resolve(),
  handleRedirectCallback: () => Promise.resolve(),
  logout: () => { },
  setOrganization: () => { },
});

const fetchPermissionsAndFeatures = async (token: string) => {
  // This endpoint fetches the permissions for the current user.
  const permissionsReq = axios.get<{ resources: Array<string> }>(
    '/api/user/resources',
    { headers: { Authorization: `Bearer ${token}` } }
  );

  // This endpoint fetches the features that are enabled for the organization of the current user.
  const featuresReq = axios.get<Record<string, any>>(
    '/api/role-permissions',
    { headers: { Authorization: `Bearer ${token}` } }
  );

  const [
    { data: { resources: permissions } },
    { data: orgFeatures },
  ] = await Promise.all([permissionsReq, featuresReq]);

  return { permissions, orgFeatures: Object.keys(orgFeatures) };
};


type Auth0Config = {
  client_id: string;
  domain: string;
  audience: string;
  scope: string;
  advancedOptions: {
    // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
    defaultScope: string;
  },
  useRefreshTokens: boolean;
}

export const AuthProvider: FC<{ config: Auth0Config, children: ReactNode }> = ({ config, children }) => {
  const [auth0Client] = useState(() => {
    const client = new Auth0Client({
      redirect_uri: window.location.origin,
      cacheLocation: 'localstorage',
      ...config,
    });
    (window as any).auth0 = client;
    return client;
  });

  const dispatch = useDispatch();
  const state = useSelector(authSelector);
  const loginWithRedirect = async (options: RedirectLoginOptions = {}) => {

    let appUrl = '';
    try {
      const { url } = await fetch('/api/api-url').then((resp) => resp.json())
      appUrl = url;
    } catch (err) {
      console.log('error', err);
    }
    try {
      const organization = options.organization || state.organization || getOrganizationFromUrl();
      if (organization) {
        await auth0Client.loginWithRedirect({
          ...options,
          redirect_uri: `${window.location.origin}/callback?organization=${organization}&appUrl=${appUrl}`,
          organization,
        });
      }
    } catch (error) {
      console.log('error', error);
      window.location.href = window.location.origin;
    }
  }


  const setOrganization = async (org) => {
    dispatch(setOrganizationId(org));
  };

  const handleRedirectCallback = async () => {
    const redirectResponse = await auth0Client.handleRedirectCallback();

    // NOTE: V2-7758 - If idP-initiated login then call getTokenSilently b/c client.isAuthenticated() will return false until token is fetched
    if (redirectResponse.appState?.targetUrl) {
      await auth0Client.getTokenSilently();
    }

    const isAuthenticated = await auth0Client.isAuthenticated();
    let token = '';
    if (isAuthenticated) {
      let appUrl = '';
      try {
        const { url } = await fetch('/api/api-url').then((resp) => resp.json())
        appUrl = url;
      } catch (err) {
        console.log('error', err);
      }

      const user = await auth0Client.getUser();
      const organization = user.org_id || state.organization || getOrganizationFromUrl();

      token = await auth0Client.getTokenSilently({
        audience: config.audience,
        scope: config.scope,
        redirect_uri: `${window.location.origin}/callback?organization=${organization}&appUrl=${appUrl}`,
        organization,
      });

      const { permissions, orgFeatures } = await fetchPermissionsAndFeatures(token);

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      dispatch(login(token, user, permissions, orgFeatures));
    }

    axios.post('/api/auth0/login/log', {
      token,
      state: isAuthenticated ? 'success' : 'login_failed'
    });
  };

  const doLogout = async () => {
    dispatch(logout());
    const hiddenIframe = document.getElementById('hidden-iframe') as HTMLIFrameElement;
    if (hiddenIframe && hiddenIframe.contentWindow) {
      hiddenIframe.contentWindow.postMessage({
        operation: 'logout'
      }, '*')
      await bPromise.delay(1500);
    }
    await auth0Client.logout({
      returnTo: `${window.location.origin}/login`,
      // localOnly: true,j
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        // get organization from auth state.
        // During callback redirect, we need to get the organization from url.
        // When refreshing the page, we need to get it from the token.
        const organization = state.organization || getOrganizationFromUrl() || parseJwt().org_id;

        if (organization) {
          const userIsAuthenticated = await auth0Client.isAuthenticated();

          if (userIsAuthenticated) {
            const [user, token] = await Promise.all([
              auth0Client.getUser(),
              auth0Client.getTokenSilently({
                audience: config.audience,
                scope: config.scope,
                redirect_uri: `${window.location.origin}/callback?organization=${state.organization}`,
                organization,
              }),
            ]);

            const { permissions, orgFeatures } = await fetchPermissionsAndFeatures(token);

            dispatch(
              initialize(
                userIsAuthenticated,
                token,
                user,
                permissions,
                orgFeatures,
              )
            );
          } else {
            dispatch(initialize(userIsAuthenticated, null, null));
          }
        } else {
          dispatch(initialize(false, null, null));
        }
      } catch (err) {
        console.error(err);
        dispatch(initialize(false, null, null));
      }
    };
    if (!state.user?.id) {
      initialise();
    }
  }, [auth0Client, dispatch, state.organization, state.user?.id]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        client: auth0Client,
        method: 'Auth0',
        loginWithRedirect,
        setOrganization,
        handleRedirectCallback,
        logout: doLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
