import React from 'react';
import type { FC } from 'react';
import { ENL_LOGO } from 'dashboard/src/constants';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => (
  <img
    alt='Logo'
    style={{ height: '43px' }}
    src={props.src || ENL_LOGO}
    {...props}
  />
)

export default Logo;
