import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaggingQueueTransaction, Tagger, TaggingQueueFilter } from 'dashboard/src/types/tagging';
import { GET_TAGGING_QUEUE_PAGE, GET_TAGGERS, ASSIGN_TRANSACTION_TAGGER, GET_ALL_USERS, UNASSIGN_TRANSACTION_TAGGER } from 'dashboard/src/lib/gql/tagging';
import { AppThunk, RootState } from 'dashboard/src/store';
import { IndexedById, PaginationOptions } from 'dashboard/src/types/paginated';
import { uniq } from 'lodash';
import _ from 'lodash';
import objFromArray from 'dashboard/src/utils/objFromArray';

interface TaggingState {
  transactions: IndexedById<TaggingQueueTransaction>;
  filter: TaggingQueueFilter;
  taggers: {
    byId: Record<string, Tagger>;
    allIds: string[];
  };
};

export const initialState: TaggingState = {
  transactions: {
    byId: {},
    allIds: [],
    total: 0,
    isLoading: false,
  },
  taggers: {
    byId: {},
    allIds: [],
  },
  filter: 'All',
};

const slice = createSlice({
  name: 'tagging',
  initialState,
  reducers: {
    replaceTransactionsPage(state, { payload }: PayloadAction<{ transactions: TaggingQueueTransaction[], total?: number, offset: number, limit: number }>) {
      state.transactions.byId = _.merge(
        state.transactions.byId,
        objFromArray(payload.transactions),
      );
      const transactionIds = payload.transactions.map((t) => t.id);
      state.transactions.allIds.splice(payload.offset, payload.limit, ...transactionIds);

      if (typeof payload.total === 'number') {
        state.transactions.total = payload.total;
      }
    },
    updateTransactionById(state, { payload }: PayloadAction<{ transactionId: string, data: Partial<TaggingQueueTransaction> }>){
      const { transactionId, data } = payload;
      state.transactions.byId[transactionId] = {
        ...state.transactions.byId[transactionId],
        ...data
      }
    },
    replaceAllTransactions(state, { payload }: PayloadAction<{ transactions: TaggingQueueTransaction[], total?: number }>) {
      state.transactions.byId = objFromArray(payload.transactions);
      state.transactions.allIds = payload.transactions.map((t) => t.id);
      if (typeof payload.total === 'number') {
        state.transactions.total = payload.total;
      }
    },
    setTotal(state, { payload }: PayloadAction<{ total: number }>) {
      state.transactions.total = payload.total;
    },
    removeTransactionsIfPresent(state, { payload }: PayloadAction<{ transactionIds: string[] }>) {
      const toRemove = uniq(payload.transactionIds.filter((id) => state.transactions.allIds.includes(id)));
      toRemove.forEach((id) => {
        delete state.transactions.byId[id];
        state.transactions.allIds.splice(state.transactions.allIds.indexOf(id), 1);
      });
    },
    setFilter(state, { payload }: PayloadAction<{ filter: TaggingQueueFilter }>) {
      state.filter = payload.filter;
    },
    setTaggers(state, { payload }: PayloadAction<{ taggers: Tagger[] }>) {
      state.taggers.byId = objFromArray(payload.taggers);
      state.taggers.allIds = payload.taggers.map(({ id }) => id);
    },
    setIsLoadingTransaction(state, { payload }: PayloadAction<boolean>) {
      state.transactions.isLoading = payload;
    },
  },
});

export const {
  replaceTransactionsPage,
  replaceAllTransactions,
  setTotal,
  removeTransactionsIfPresent,
  setFilter,
  setTaggers,
  setIsLoadingTransaction,
} = slice.actions;

export const getTaggers = (): AppThunk => async (dispatch, getState, apollo) => {
  const { data: { getTaggers } } = await apollo.query({ query: GET_TAGGERS });
  dispatch(setTaggers({ taggers: getTaggers }));
}

export const getAllUsers = (): AppThunk => async (dispatch, getState, apollo) => {
  const { data: { getAllUsers } } = await apollo.query({ query: GET_ALL_USERS });
  dispatch(setTaggers({ taggers: getAllUsers }))
}

// getTransactionsPage allows you to get a page of transactions.
// If it's called twice in quick succession, then the first call may finish after the second.
// To avoid this, the second call will cancel the first call's ability to dispatch.
// And the third will cancel the second's ability to dispatch, and so on.
let cancelLastGetTransactionsPageCall = () => {};
export const getTransactionsPage = (pagination: PaginationOptions): AppThunk => async (dispatch, getState, apollo) => {
  // First of all, cancel the last getTransactionsPage call, and let this call be canceled as well
  cancelLastGetTransactionsPageCall();
  let isCancelled = false;
  cancelLastGetTransactionsPageCall = () => isCancelled = true;

  // Now actually fetch
  const { filter } = getState().tagging;
  const variables: any = { pagination };

  if (filter === 'Assigned to me') {
    variables.taggerId = getState().auth.user.id;
  } else if (filter === 'Not assigned to me') {
    variables.taggerIdIsNot = getState().auth.user.id;
  }

  try {
    dispatch(setIsLoadingTransaction(true));

    const { data: { taggableTransactions }, error } = await apollo.query({
      query: GET_TAGGING_QUEUE_PAGE,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (error) {
      throw error;
    }

    if (isCancelled) return;
    const { total, transactions } = taggableTransactions;
    // If the data fetched is for a different filter, then replace all transactions with these. Otherwise, just add these to the list
    if (filter !== getState().tagging.filter) {
      dispatch(replaceAllTransactions({ transactions, total }));
    } else {
      dispatch(replaceTransactionsPage({ transactions, total, offset: pagination.pageIndex * pagination.limit, limit: pagination.limit }));
    }
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setIsLoadingTransaction(false));
  }
}

export const assignTransactionTagger = (payload: { transactionId: string, taggerId: string, taggerName: string, taggerEmail: string }): AppThunk => async (dispatch, getState, apollo) => {
  const { data: { setTransactionTagger } } = await apollo.mutate({
    mutation: ASSIGN_TRANSACTION_TAGGER,
    variables: payload,
  });

  dispatch(slice.actions.updateTransactionById({ transactionId: payload.transactionId, data: setTransactionTagger }));
}

export const unAssignTransactionTagger = (payload: { transactionId: string, taggerId: string, taggerName: string, taggerEmail: string }): AppThunk => async (dispatch, getState, apollo) => {
  const { data: { sendUnAssignTaggerAlert } } = await apollo.mutate({
    mutation: UNASSIGN_TRANSACTION_TAGGER,
    variables: payload,
  });

  dispatch(slice.actions.updateTransactionById({ transactionId: payload.transactionId, data: sendUnAssignTaggerAlert }));
}

export const reducer = slice.reducer;
export const selector = (state: RootState) => state.tagging;
export default slice;
