import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { getOrganizationFromUrl } from 'dashboard/src/utils/getOrganizationFromUrl';

interface GuestGuardProps {
  children?: ReactNode;
}


const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, organization, isInitialised } = useAuth();
  const org = organization || getOrganizationFromUrl();
  if (!org && isInitialised) {
    return <Redirect to='/portal' />;
  }
  if (isAuthenticated) {
    return <Redirect to='/app/reports/dashboard' />;
  }

  return (
    <>{children}</>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
