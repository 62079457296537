import React from 'react';
import type { FC } from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'dashboard/src/components/GlobalStyles';
import ScrollReset from 'dashboard/src/components/ScrollReset';
// import CookiesNotification from 'src/components/CookiesNotification';
// import SettingsNotification from 'src/components/SettingsNotification';
import { AuthProvider } from 'dashboard/src/contexts/Auth0Context';
import useSettings from 'dashboard/src/hooks/useSettings';
import { createTheme } from 'dashboard/src/theme';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { LDIdentity } from './contexts/LaunchDarklyContext';
import useTheme from './hooks/useTheme';
import { adaptOrgStyleToThemeOptions } from './theme/utils/adaptOrgStyleToThemeOptions';
import { useEffectOnce } from 'react-use';
import { routes, RoutesView } from './routes';


const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App: FC<{ auth0Config: any }> = ({ auth0Config }) => {
  const { settings } = useSettings();
  const { getTheme, themeName, themeColors, themePreviewMode, themePreviewOptions } = useTheme();
  let createdTheme = {}

  useEffectOnce(() => {
    getTheme();
  })

  if(themePreviewMode){
    createdTheme = createTheme({
      direction: settings.direction,
      responsiveFontSizes: settings.responsiveFontSizes,
      theme: themeName
    }, adaptOrgStyleToThemeOptions(themePreviewOptions));
  }else if(themeName === 'ORG_STYLE'){
    createdTheme = createTheme({
      direction: settings.direction,
      responsiveFontSizes: settings.responsiveFontSizes,
      theme: themeName
    }, adaptOrgStyleToThemeOptions(themeColors));
  }
  else{
    createdTheme = createTheme({
      direction: settings.direction,
      responsiveFontSizes: settings.responsiveFontSizes,
      theme: themeName
    });
  }


  return (
    <ThemeProvider theme={createdTheme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <AuthProvider config={auth0Config}>
              <LDIdentity>
                <FirebaseProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  {/* <CookiesNotification /> */}
                  {/* <SettingsNotification /> */}
                  <RoutesView routes={routes} />
                  <iframe
                    allow='camera;microphone'
                    id='hidden-iframe'
                    title='hidden-iframe'
                    frameBorder='0'
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'none',
                    }}
                    src={`${(window as any).baseUrl}/enterprise/dashboard`}
                  />
                </FirebaseProvider>
              </LDIdentity>
            </AuthProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
