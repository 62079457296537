import { WebSocketLink } from './WebSocketLink';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, HttpLink, split, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { parseJwt } from 'dashboard/src/utils/parseToken';
import { auth0Config } from '../config';
import { setToken } from 'dashboard/src/slices/auth';

const createLink = (url: string) => {
  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = localStorage.getItem('token');
    const tokenData = parseJwt(token);
    const isExpired = tokenData.exp < Date.now() / 1000;

    if (token && isExpired) {
      const { organization } = (window as any).store?.getState()?.auth || {};
      try {
        token = await (window as any).auth0.getTokenSilently({
          audience: auth0Config.audience,
          scope: auth0Config.scope,
          organization,
        });
        (window as any).store?.dispatch(setToken(token));
      } catch (error) {
        await (window as any).auth0.loginWithRedirect({
          redirect_uri: `${window.location.origin}/callback?organization=${organization}`,
          organization,
        });
      }
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const http = new HttpLink({ uri: `${window.location.origin}${url}` });

  return authLink.concat(http);
};


export const initApollo = (withSubscriptions = true) => {
  const apiPath = process.env.NX_GQL_PATH || '/graphql';

  const link = createLink(apiPath);
  const gqlWsLink = withSubscriptions && new WebSocketLink({
    url: `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${window.location.host}/api/subscriptions`,
    retryAttempts: 3,
    lazy: true,
  });


  return new ApolloClient({
    ssrMode: false,
    connectToDevTools: true,
    link: withSubscriptions
      ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        gqlWsLink,
        link
      )
      : link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // allPosts: concatPagination(),
          },
        },
      },
    }),
  });
};
